import moment from "moment";
import { parse } from "path";
import { element } from "prop-types";

export interface ServerInfo {
  server_type: string;
}

export type IotData = {
  CurrentSubMenuNo: number;
  CurrentMenuNo: number;
  connected: boolean;
} & {
  InverterFreqReadout: number;
  Inverter_loadPowP: number;
  PressureIN: number;
  PressureOUT: number;
  Temperature_Water: number;
  Temperature_igbt: number;
  Temperature_ctrl_board: number;
  Temperature_Motor: number;
  Temperature_Room: number;
  FlowSw: number;
  VibrationX: number;
  VibrationY: number;
  VibrationZ: number;
  AppT4LejePT1000_1: number;
  AppT5ViklingPT1000_2: number;
  AppF1Dynflow: number;
  AppN1RpmKoblingsFrekvens: number;
  ErrorCode1: number;
  ErrorCode2: number;
  ErrorCode3: number;
  WarningCode1: number;
  WarningCode2: number;
  MachineStandby: number;
  AppUnitType: number;
  AppBoosterType: number;
  AppRunningUserNo: number;
  HourOffsetFromUTC: number;
  CurrentMenuNo: number;
  CurrentSubMenuNo: number;
  Diodes: number;
  NavigatorEnabled: number;
  PressureAtButton: number;
  [key: string]: any;
  DiodeState: number;
  AnalogState: number;
};

export type HistoricIotData = {
  Time: string;
  InvIdx: string;
  Freq: string;
  Power: string;
  "Pressure OUT": string;
  "Pressure IN": string;
  "Temp Water": string;
  "Temp IGBT": string;
  "Temp BOARD": string;
  "Temp Motor": string;
  TouchXYcord: string;
  FlowSw: string;
  VibrationX: string;
  VibrationY: string;
  VibrationZ: string;
  T4LejePT1000_1: string;
  T5ViklingPT1000_2: string;
  F1Dynflow: string;
  N1Rpm: string;
  ErrorCode1: string;
  ErrorCode2: string;
  ErrorCode3: string;
  WarningCode1: string;
  WarningCode2: string;
  AppRunningUserNo: string;
  AppRunningUserNoIdx: number;
};
export type staticData = {
  [key: string]: string;

}
export type telemetryData = {
  InverterFreqReadout: number;
  Inverter_loadPowP: number;
  PressureIN: number;
  PressureOUT: number;
  Temperature_ctrl_board: number;
  Temperature_Water: number;

  Temperature_igbt: number;
  Temperature_Motor: number;
  FlowSw: number;
  VibrationX: number;
  VibrationY: number;
  VibrationZ: number;
  AppF1Dynflow: number;
  AppT5ViklingPT1000_2: number;
  AppT4LejePT1000_1: number;
  AppN1RpmKoblingsFrekvens: number;
  [key: string]: number;
};

export type DatedHistoricIotData = { date: number; data: telemetryData };

export type DatedIotData = { date: number; data: IotData };

export type UpTimeDataFromServer = { "Start Time": string; "Stop Time": string };

export function ParseUpTimeData(uptimeDatasFromServer: Record<string, UpTimeDataFromServer>): UpTimeSpan[] {
  return Object.values(uptimeDatasFromServer).reduce((arr, uptimeDataFromServer) => {
    let start_date = moment(moment(uptimeDataFromServer["Start Time"], "HH:mm:ss DD.MM.YYYY").add(1, "M"));
    let end_date = moment(moment(uptimeDataFromServer["Stop Time"], "HH:mm:ss DD.MM.YYYY").add(1, "M"));


    if (!start_date.isValid() || !end_date.isValid() || start_date > end_date) {
      return arr;
    }
    let uptime: UpTimeSpan = { startDate: start_date.valueOf(), endDate: end_date.valueOf() };
 
    arr.push(uptime);
    return arr;
  }, [] as UpTimeSpan[]);
}

// function Parse_Csv(telemtryData : any) : IotData {
//     let iotData = JSON.parse(telemtryData).IotData;
//     let parsed_data : IotData = {
//         sub_scr : iotData.subScr as number,
//         menu : iotData.Menu as string
//     }
//     return parsed_data;
// }

export function convertHistoricIotData(json: HistoricIotData[]): DatedHistoricIotData[] {
  return json.map((iotData) => {

    let data: telemetryData = {
      InverterFreqReadout: parseInt(iotData.Freq),
      Inverter_loadPowP: parseInt(iotData.Power),
      PressureIN: parseInt(iotData["Pressure IN"]),
      PressureOUT: parseInt(iotData["Pressure OUT"]),
      Temperature_ctrl_board: parseInt(iotData["Temp BOARD"]),
      Temperature_Water: parseInt(iotData["Temp Water"]),

      Temperature_igbt: parseInt(iotData["Temp IGBT"]),
      Temperature_Motor: parseInt(iotData["Temp Motor"]),
      FlowSw: parseInt(iotData.FlowSw),
      VibrationX: parseInt(iotData.VibrationX),
      VibrationY: parseInt(iotData.VibrationY),
      VibrationZ: parseInt(iotData.VibrationZ),
      AppF1Dynflow: parseInt(iotData.F1Dynflow),
      AppT5ViklingPT1000_2: parseInt(iotData.T5ViklingPT1000_2),
      AppT4LejePT1000_1: parseInt(iotData.T4LejePT1000_1),
      AppN1RpmKoblingsFrekvens: parseInt(iotData.N1Rpm),
    };
    let date = new Date();
    let [hour, min, sec] = iotData.Time.split(":");

    date.setHours(parseInt(hour));
    date.setMinutes(parseInt(min));
    date.setSeconds(parseInt(sec));
    return {
      date: date.valueOf(),
      data: data,
    };
  });
}
export type UpTimeSpan = { startDate: number; endDate: number };

export function ParseIotDataForUpTime(iotData: DatedHistoricIotData[]): [number | null, UpTimeSpan[]] {
  let IsRunning = false;
  let uptimeArr: UpTimeSpan[] = [];
  let CurrStartTime: number | null = null;

  for (const entry of iotData) {
    if (entry.data.Inverter_loadPowP > 100 && IsRunning === false && CurrStartTime === null) {
      CurrStartTime = entry.date;
      IsRunning = true;
    }
    if (entry.data.Inverter_loadPowP < 100 && IsRunning === true && CurrStartTime !== null) {
      uptimeArr.push({ startDate: CurrStartTime!, endDate: entry.date });
      CurrStartTime = null;
      IsRunning = false;
    }
  }

  return [CurrStartTime, uptimeArr];
}

export function GetErrorCodes(data: IotData): Record<string, number> {
  return {
    ErrorCode1: data.ErrorCode1,
    ErrorCode2: data.ErrorCode2,
    ErrorCode3: data.ErrorCode3,
    WarningCode1: data.WarningCode1,
    WarningCode2: data.WarningCode2,
  };
}

function GetSubScreenFields(telemetryDataObj: Record<string, number>): Record<string, number> {
  let values: [string, number][] = Object.entries(telemetryDataObj);
  let idxStartSubScreenData = values.findIndex(([key, _]) => key == "deviceId");
  let idxEndSubScreenData = values.findIndex(([key, _]) => key == "ErrorCode1");

  return Object.fromEntries(values.slice(idxStartSubScreenData + 1, idxEndSubScreenData));
}
