import React, { useEffect } from "react";

import { Grid, Typography } from "@mui/material";
import {
  DataInfoField as DataInfoFieldModel,
  UiElement,
  InfoField as infoFieldModel,
} from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";
import {
  convertNameToEncoding,
  convertValueToEncoding,
  Encoding,
  useEncodingState,
} from "pages/Navigator/Models/DataEncoding";

export default function DataInfoField({
  element,
  ScreenUpdate,
  disabled,
}: {
  element: UiElement;
  ScreenUpdate?: () => void;
  disabled: boolean;
}) {
  return <InnerInfoField infoField={element}></InnerInfoField>;
}

function InfoFieldWrapper({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}): JSX.Element {
  return (
    <div className=" flex h-full max-h-[3vmax] flex-grow flex-wrap  justify-center pb-1.5 pt-1.5  text-[length:inherit] ">
      {children}
    </div>
  );
}

function InnerInfoField({ infoField }: { infoField: DataInfoFieldModel }) {
  if (infoField.telemtryField) {
    return (
      <TelemetryInfoField
        infoField={infoField}
        telemtryField={infoField.telemtryField}
      ></TelemetryInfoField>
    );
  }
  if (infoField.staticField) {
    return (
      <StaticInfoField
        infoField={infoField}
        staticField={infoField.staticField}
      ></StaticInfoField>
    );
  }

  return (
    <InfoFieldWrapper>
      <div className="bottom-0 flex basis-6/12 justify-center">
        <p className="bottom-0  text-center text-[1em] text-[white]">
          {infoField.display_name}
        </p>
      </div>
      <div className="auto-top bottom-0 flex  h-full basis-6/12 justify-start bg-white  ">
        <p className="bottom-0 text-center text-[1em] text-black">
          {infoField.fieldVal}
        </p>
      </div>
    </InfoFieldWrapper>
  );
  //default case
}

function StaticInfoField({
  infoField,
  staticField,
}: {
  infoField: infoFieldModel;
  staticField: string;
}) {
  const Server = GetServer();

  const staticData = Server.getStaticData();

  const { encoding } = useEncodingState();
  return (
    <DataInfoFieldWithValue
      infoField={infoField}
      value={staticData[staticField] ?? "couldnt fetch field"}
      encoding={encoding}
    ></DataInfoFieldWithValue>
  );
}

function TelemetryInfoField({
  infoField,
  telemtryField,
}: {
  infoField: infoFieldModel;
  telemtryField: string;
}) {
  const Server = GetServer();
  const LatestData = Server.getLatestData();
  let [val, setVal] = React.useState<number>(LatestData.data[telemtryField]);
  const { encoding } = useEncodingState();
  useEffect(() => {
    function SetTelemetry(data: DatedIotData) {
      if (infoField.fieldUnit !== undefined) {
        setVal(
          convertValueToEncoding(
            encoding,
            data.data[telemtryField],
            infoField.fieldUnit,
          ),
        );
        return;
      }
      setVal(data.data[telemtryField]);
    }
    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, [Server, infoField, telemtryField]);
  return (
    <DataInfoFieldWithValue
      infoField={infoField}
      encoding={encoding}
      value={val.toString()}
    ></DataInfoFieldWithValue>
  );
}

function DataInfoFieldWithValue({
  infoField,
  encoding,
  value,
}: {
  infoField: infoFieldModel;
  encoding: Encoding;
  value: string;
}) {
  if (infoField.fieldUnit) {
    return (
      <InfoFieldWrapper>
        <div className="bottom-0 flex h-full  basis-6/12">
          <p className="bottom-0 flex h-full items-center text-[1.2em] text-white ">
            {infoField.display_name}
          </p>
        </div>
        <div className="relative bottom-0 top-auto  flex h-full  basis-6/12 justify-center bg-white">
          <p className="bottom-0 flex h-full items-center text-center  text-[1em] text-[darkblue] ">
            {value}
          </p>
          <p className="absolute bottom-0 right-0 px-1 text-[1em] text-[darkblue]">
            {`(${convertNameToEncoding(encoding, infoField.fieldUnit)})`}
          </p>
        </div>
      </InfoFieldWrapper>
    );
  }
  return (
    <InfoFieldWrapper>
      <div className="bottom-0  flex h-full basis-6/12 items-center justify-start">
        <p className="bottom-0 text-center text-[1.2em] text-white">
          {infoField.display_name}
        </p>
      </div>
      <div className="auto-top bottom-0 flex h-full basis-6/12 items-center justify-center bg-white ">
        <p className="bottom-0 text-center text-[1em] text-black">{value}</p>
      </div>
    </InfoFieldWrapper>
  );
}
