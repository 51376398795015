import React, { useEffect } from "react";
import {
  GetPopUpBarListener,
  PopUpBarType,
} from "../Models/HistoricViewlistener";
import {
  Menu as MenuModel,
  Menus,
  SubScreen as SubScreenModel,
  UiElement,
} from "../Models/Menus";
import { GetServer } from "../Models/ServerCommuncation/Server";
import { DatedIotData } from "../Models/ServerCommuncation/serverInterFace";
import DashBoard from "./DashBoard/Dashboard";
import DiodeBox from "./DashBoard/diodebox";
import InfoBox from "./DashBoard/infobox";
import ShowTimeErrorBarView from "./HistoricView/ErrorHistoryViewAlternative";
import HistoricUpTimeView from "./HistoricView/UpTimeHistoryView";
import MenuView from "./Menu";
import { TelemetryChartWrapper } from "./uiElements/noninteractive/Telemetry/TelemetryChartWrapper";
//@ts-ignore
import displayImage from "shared/utils/images/DisplayBorder.png";
import { ErrorLogEntry } from "../Models/Errorlog/ErrorLogEntry";
import DisplayInverters from "./DashBoard/TripCounterBox";
import LeftPanel from "./DashBoard/LeftPanel";

export default function Screen({
  machineConfig,
}: {
  machineConfig: Record<string, any>;
}) {
  return (
    <div className="mx-auto max-w-full px-4 py-6 sm:px-6 lg:px-8 ">
      {/* Breakpoint Visualizer Row */}
      {/* <div className="mb-4 grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6">
        <div className="bg-red-500 p-2 text-center text-white">
          XS (default)
        </div>
        <div className="hidden bg-blue-500 p-2 text-center text-white sm:block">
          SM (≥640px)
        </div>
        <div className="hidden bg-green-500 p-2 text-center text-white md:block">
          MD (≥768px)
        </div>
        <div className="hidden bg-yellow-500 p-2 text-center text-black lg:block">
          LG (≥1024px)
        </div>
        <div className="hidden bg-purple-500 p-2 text-center text-white xl:block">
          XL (≥1280px)
        </div>
        <div className="hidden bg-pink-500 p-2 text-center text-white 2xl:block">
          2XL (≥1536px)
        </div>
      </div> */}

      {/* Main 3 column grid */}
      <div className="grid grid-cols-1  items-start gap-4 lg:grid-cols-2 xl:grid-cols-4 xl:gap-8">
        {/* Left column */}

        <div className="order-2 grid grid-cols-1 xl:col-span-2 2xl:order-1 2xl:col-span-1">
          <section aria-labelledby="section-2-title">
            <h2 id="section-2-title" className="sr-only">
              Section title
            </h2>
            <div className="overflow-visible rounded-lg bg-white shadow">
              <div className="">
                <LeftPanel />
              </div>
            </div>
          </section>
        </div>

        {/* center column */}
        <div className="order-1 col-span-2 lg:col-span-2 xl:col-span-4 2xl:col-span-2">
          <section aria-labelledby="section-1-title">
            <h2 id="section-1-title" className="sr-only">
              Section title
            </h2>
            <div className="overflow-visible rounded-lg bg-white shadow">
              <div className="p-6">
                <div className="relative flex  w-full items-center justify-center">
                  <DeviceView machineConfig={machineConfig} />
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <div className="order-4 grid grid-cols-1 gap-4">
          <section aria-labelledby="section-2-title">
            <h2 id="section-2-title" className="sr-only">
              Section title
            </h2>
            <div className="overflow-visible rounded-lg bg-white shadow">
              <DisplayInverters />
            </div>
          </section>
        </div> */}

        {/* <div className="order-5 grid grid-cols-1 gap-4">
          <section aria-labelledby="section-2-title">
            <h2 id="section-2-title" className="sr-only">
              Section title
            </h2>
            <div className="overflow-visible rounded-lg bg-white shadow">
              <DiodeBox />
            </div>
          </section>
        </div> */}
        {/* Right column */}
        <div className="order-3 grid grid-cols-1 xl:col-span-2 2xl:order-1 2xl:col-span-1">
          <section aria-labelledby="section-2-title">
            <h2 id="section-2-title" className="sr-only">
              Section title
            </h2>
            <div className="overflow-visible rounded-lg bg-white shadow">
              <div className="">
                <DashBoardView ShowTelemetry={true} />
              </div>
            </div>
          </section>
        </div>
        <div className="order-6 col-span-2 grid grid-cols-1">
          <section aria-labelledby="section-2-title">
            <h2 id="section-2-title" className="sr-only">
              Section title
            </h2>
            <div className="overflow-visible rounded-lg bg-white shadow">
              <TelemetryChartWrapper />
            </div>
          </section>
        </div>
        <div className="order-7 col-span-2 grid grid-cols-1">
          <section aria-labelledby="section-2-title">
            <h2 id="section-2-title" className="sr-only">
              Section title
            </h2>
            <div className="overflow-visible rounded-lg bg-white shadow">
              <TelemetryChartWrapper />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

function DeviceView({ machineConfig }: { machineConfig: Record<string, any> }) {
  const Server = GetServer();
  const [menuData] = React.useState(new Menus(machineConfig));
  const [Menu, SetMenu] = React.useState<null | MenuModel>(null);
  const [subScreen, SetSubScreen] = React.useState<null | SubScreenModel>(null);
  const [IsUpdating, SetIsUpdating] = React.useState<boolean>(false);
  let prevTelemtry = React.useRef<null | DatedIotData>(null);
  let currTelemetry = React.useRef<null | DatedIotData>(null);
  let isUpdatingRef = React.useRef<boolean>(IsUpdating);
  const TimeOutRef = React.useRef<NodeJS.Timeout | null>(null);
  function GetUiElements(): UiElement[] {
    return machineConfig.globalUiElements as UiElement[];
  }

  function SetScreenUpdating() {
    SetIsUpdating(true);
    prevTelemtry.current = currTelemetry.current;
    isUpdatingRef.current = true;
    if (TimeOutRef.current != null) {
      clearTimeout(TimeOutRef.current);
    }
    TimeOutRef.current = setTimeout(() => {
      if (isUpdatingRef.current === true) {
        GetServer().SendCommand("SetNavigatorButton", "GoBack", () => {});
      }
    }, 35000);
  }
  function CheckTelemtryData(curr: DatedIotData): boolean {
    if (prevTelemtry.current == null) {
      return false;
    }

    const keys = [
      "CurrentSubMenuNo",
      "CurrentMenuNo",
      "PressureAtButton",
      "MachineStandby",
      "BarButtonSelected",
      "ButtonPressed",
    ];

    for (const key of keys) {
      if (curr.data[key] !== prevTelemtry.current!.data[key]) {
        return true;
      }
    }
    return false;
  }

  function TelemtryCallBack(telemtryData: DatedIotData) {
    currTelemetry.current = telemtryData;

    if (
      subScreen?.SubScreenName !== telemtryData.data.CurrentSubMenuNo ||
      Menu?.menuNo !== telemtryData.data.CurrentMenuNo ||
      !subScreen
    ) {
      let menu = menuData?.GetMenu(telemtryData.data.CurrentMenuNo.toString());
      if (!menu) {
        SetMenu(null);
        return;
      }
      let subscreen = menu.GetSubScreen(
        telemtryData.data.CurrentSubMenuNo.toString(),
      );
      SetMenu(menu);

      SetSubScreen(subscreen);
    }
    if (IsUpdating && CheckTelemtryData(telemtryData)) {
      SetIsUpdating(false);
      isUpdatingRef.current = false;
    }
  }

  useEffect(() => {
    Server.on_telemtry(TelemtryCallBack);

    return () => {
      Server.off_telemtry(TelemtryCallBack);
    };
  });

  return (
    <div className="relative flex h-auto w-full">
      <MenuView
        menu={Menu}
        subScreen={subScreen}
        screenUpdating={IsUpdating}
        globalUiElements={GetUiElements()}
        ScreenUpdate={SetScreenUpdating}
      />
    </div>
  );
}

type BarUpView = (
  arg?: string[],
  errologEntries?: ErrorLogEntry[],
) => JSX.Element;
function BarTimeView(): JSX.Element {
  const BarMap: Record<PopUpBarType, BarUpView> = {
    ErrorTime: (arg?: string[], errologEntries?: ErrorLogEntry[]) => {
      return (
        <ShowTimeErrorBarView
          displayname={arg![0]}
          errorName={arg![1]}
          ErrorLogsList={errologEntries!}
        />
      );
    },
    UpTime: (arg?: string[], errologEntries?: ErrorLogEntry[]) => {
      return <HistoricUpTimeView />;
    },
  };
  const [BarView, SetBarView] = React.useState<JSX.Element>(
    <HistoricUpTimeView />,
  );
  function ChangeBarUpView(
    type: PopUpBarType,
    val?: string[],
    errologEntries?: ErrorLogEntry[],
  ) {
    const Component = BarMap[type](val, errologEntries);
    SetBarView(Component);
  }
  useEffect(() => {
    let listener = GetPopUpBarListener();
    listener.addListener(ChangeBarUpView);
    return () => {
      listener.removeListener(ChangeBarUpView);
    };
  }, []);
  return BarView;
}

function DashBoardView({ ShowTelemetry }: { ShowTelemetry: boolean }) {
  if (!ShowTelemetry) {
    return null;
  }

  return <DashBoard />;
}
