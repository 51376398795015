//this is the next task.

import React from "react";

import { GetPopUpBarListener } from "../../Models/HistoricViewlistener";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import ErrorLogView from "./ErrorLogView";
import SettingsView from "./Settings";
import TelemtryDataView from "./TelemetryView";

function classNames(...classes: (string | undefined | null | false)[]): string {
  return classes.filter(Boolean).join(" ");
}

type dashBoardMenuType = "telemetry" | "errorlog" | "settings";

type dashBoardMenuView = () => JSX.Element | null;

const DashBoardMenuViews: Record<dashBoardMenuType, dashBoardMenuView> = {
  telemetry: TelemtryDataView,
  errorlog: ErrorLogView,
  settings: SettingsView,
};
export default function DashBoard() {
  const [dashBoardMenu, setDashBoardMenu] =
    React.useState<dashBoardMenuType>("telemetry");
  let DashBoardMenuView = DashBoardMenuViews[dashBoardMenu];
  const server = GetServer();
  const listener = GetPopUpBarListener();

  return (
    <div className="">
      <div>
        <div className="">
          <nav
            aria-label="Tabs"
            className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
          >
            <button
              aria-current={dashBoardMenu === "telemetry" ? "page" : undefined}
              className={classNames(
                dashBoardMenu === "telemetry"
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                "rounded-l-lg",
                "group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10",
              )}
              onClick={() => {
                if (!server.machineIsRebooting()) {
                  listener.callListeners("UpTime");
                  setDashBoardMenu("telemetry");
                }
              }}
            >
              <span>Telemetry</span>
              <span
                aria-hidden="true"
                className={classNames(
                  dashBoardMenu === "telemetry"
                    ? "bg-blue-500"
                    : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5",
                )}
              />
            </button>
            <button
              aria-current={dashBoardMenu === "errorlog" ? "page" : undefined}
              className={classNames(
                dashBoardMenu === "errorlog"
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                "group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10",
              )}
              onClick={() => {
                if (!server.machineIsRebooting()) {
                  setDashBoardMenu("errorlog");
                }
              }}
            >
              <span>Error Log</span>
              <span
                aria-hidden="true"
                className={classNames(
                  dashBoardMenu === "errorlog"
                    ? "bg-blue-500"
                    : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5",
                )}
              />
            </button>
            <button
              aria-current={dashBoardMenu === "settings" ? "page" : undefined}
              className={classNames(
                dashBoardMenu === "settings"
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                "rounded-r-lg",
                "group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10",
              )}
              onClick={() => {
                if (!server.machineIsRebooting()) {
                  listener.callListeners("UpTime");
                  setDashBoardMenu("settings");
                }
              }}
            >
              <span>Settings</span>
              <span
                aria-hidden="true"
                className={classNames(
                  dashBoardMenu === "settings"
                    ? "bg-blue-500"
                    : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5",
                )}
              />
            </button>
          </nav>
        </div>
      </div>

      <div className="h-[650px] max-h-[650px] overflow-hidden overflow-y-auto">
        <DashBoardMenuView />
      </div>
    </div>
  );
}
