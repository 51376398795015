import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DeIntializeServer,
  HttpServer,
  InitializeServer,
  SendCommand,
  ServerExist,
  Version,
} from "../Models/ServerCommuncation/Server";
import {
  DisconnectErrorType,
  IoSocket,
} from "../Models/ServerCommuncation/Socket";
import { DatedIotData } from "../Models/ServerCommuncation/serverInterFace";
import ClientMenuPage from "./ClientMenuPage";
import "../dashboard.css";
import { useIdleTimer } from "react-idle-timer";


const TEN_MINUTES = 1000 * 60 * 10;
export default function NavigatorApp() {
  const navigate = useNavigate();
  const state: {
    serialNumber?: string;
    serviceCode?: string;
    DeinitServer?: boolean;
    userDiconnected?: boolean;
    version: Version;
  } = useLocation().state;
  window.history.replaceState({ state: {} }, "");
  const [deviceConnected, setDeviceConnected] = React.useState({
    state: false,
    has_been_true: false,
  });
  const [Server, setServer] = React.useState<HttpServer | null>(null);
  useEffect(() => {
    window.onbeforeunload = (event) => {
      if (ServerExist()) {
        DeIntializeServer();
      }

      SendCommand(
        "SetNavigatorDisconnect",
        state.serialNumber!,
        state.serviceCode!,
        "",
        (data, err) => {},
      );
      return undefined;
    };
    window.onpopstate = (event) => {
      if (ServerExist()) {
        DeIntializeServer();
      }
      SendCommand(
        "SetNavigatorDisconnect",
        state.serialNumber!,
        state.serviceCode!,
        "",
        (data, err) => {},
      );
      return undefined;
    };
  });
  useIdleTimer({
    onIdle: () => {
      SendCommand(
        "SetNavigatorDisconnect",
        state.serialNumber!,
        state.serviceCode!,
        "",
        (data, err) => {},
      );

      DeIntializeServer();
      navigate("/navigator/connect", { state: { userInactive: true } });
    },
    timeout: TEN_MINUTES,
  });

  useEffect(() => {
    if (
      deviceConnected.state === false &&
      deviceConnected.has_been_true === true &&
      Server !== null
    ) {
      DeIntializeServer();
      setServer(null);
    }
  }, [Server, deviceConnected]);
  useEffect(() => {
    if (Server) {
      return;
    }
    function DisconnectFromDeviceWithError(err: DisconnectErrorType) {
      switch (err) {
        case "Machine": {
          navigate("/navigator/connect", {
            state: { errorMessage: "Machine disconnected" },
          });
          break;
        }
        case "Server": {
          navigate("/navigator/connect", {
            state: {
              errorMessage:
                "Server disconnected. Machine did not disconnect navigator mode. Please ask user to disconnect navigator mode",
            },
          });
          break;
        }
        case "NoError": {
          SendCommand(
            "SetNavigatorDisconnect",
            state.serviceCode!,
            state.serialNumber!,
            "",
            () => {},
          );

          navigate("/navigator/connect");
          break;
        }
      }

      setDeviceConnected({ state: false, has_been_true: false });
      if (Server !== null) {
        DeIntializeServer();

        setServer(null);
      }
    }
    const IntializeAndSetServer = async () => {
      setServer(
        await InitializeServer(
          new IoSocket(state.serialNumber!),
          state.serialNumber!,
          state.serviceCode!,
          state.version,
          DisconnectFromDeviceWithError,
        ),
      );
    };
    if (!state?.serialNumber) {
      navigate("/navigator/connect", {
        state: { errorMessage: "Machine disconnected" },
      });
      return;
    }

    IntializeAndSetServer();
  }, [Server]);

  useEffect(() => {
    if (!Server) {
      return;
    }

    function LookForNavigatorMode(data: DatedIotData) {
      if (
        data.data.NavigatorEnabled === 1 &&
        deviceConnected.has_been_true === false
      ) {
        setDeviceConnected({
          state: data.data!.NavigatorEnabled === 1,
          has_been_true: true,
        });

        return;
      }

      if (
        data.data.NavigatorEnabled === 0 &&
        deviceConnected.has_been_true === true
      ) {
        Server?.disconnect_with_reason("Machine");
        setDeviceConnected({
          state: data.data!.NavigatorEnabled === 0,
          has_been_true: true,
        });

        return;
      }
      setDeviceConnected((prev) => prev);
    }

    Server.on_telemtry(LookForNavigatorMode);

    return () => {
      Server.off_telemtry(LookForNavigatorMode);
    };
  }, [Server, deviceConnected]);

  if (!deviceConnected.state || !state?.serialNumber) {
    return (
      <div className="flex h-full w-full flex-col content-center justify-center">
        <div className="flex justify-center">
          {" "}
          <p className="flex items-center justify-center  text-[1em] font-semibold tracking-wide text-black">
            succesfully established connection to machine.. Loading settings
          </p>
        </div>

        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="h-16 w-16 animate-spin rounded-full border-4 border-t-4 border-white border-t-transparent"></div>
        </div>
        {/* <ProgressCircle size={"5vmax"}></ProgressCircle> */}
      </div>
    );
  }
  return (
    <div className="min-h-full">
      <ClientMenuPage machineId={state.serialNumber} />
    </div>
  );
  // return <div className="min-h-full"><ClientMenuPage machineId={state.serialNumber}></ClientMenuPage></div>;
}
