import React, { useEffect } from "react";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { Get_Keys_Display_Name } from "../../Models/ServerCommuncation/Settings";
import { DatedIotData } from "../../Models/ServerCommuncation/serverInterFace";
import {
  HasNoDetailedView,
  MapNameToUnit,
  ParseTelemtryNames,
  convertTelemtryNameToEncoding,
} from "./DashBoardParsing";
import {
  convertNameToEncoding,
  convertValueToEncoding,
  useEncodingState,
} from "../../Models/DataEncoding";
import { ProgressCircle } from "../utils";

export default function TelemtryDataView() {
  const Server = GetServer();

  const [telemetryData, setTelemetryData] = React.useState<DatedIotData[]>([]);
  const [currentTelemetryData, setCurrentTelemtryData] =
    React.useState<DatedIotData | null>(null);

  const [searchText, setSearchText] = React.useState<string>();
  const [keys, setKeys] = React.useState<string[]>([]);

  const setData = (data: DatedIotData[]) => {
    setTelemetryData((_) => {
      setCurrentTelemtryData(data[data.length - 1]);

      return data;
    });
  };
  useEffect(() => {
    Server.on_telemtry_last_10_min(setData);

    return () => {
      Server.off_telemtry_last_10_min(setData);
    };
  });
  useEffect(() => {
    if (telemetryData.length !== 0) {
      const keys = Get_Keys_Display_Name(telemetryData[0].data, searchText);
      setKeys(keys);
    }
  }, [telemetryData, searchText]);
  if (currentTelemetryData === null) {
    return <ProgressCircle />;
  }
  return (
    <>
      <div className="px-4 py-3 sm:px-6">
        <input
          id="Search"
          name="Search"
          type="text"
          placeholder="Search for telemetry (e.g., pressure, temperature)"
          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value);
          }}
        />
      </div>
      <div className="border-t border-gray-100 h-[600px] max-h-[600px] overflow-y-auto ">
        <dl className="divide-y divide-gray-100">
          {keys.map((key) => {
            return (
              <TelemetricView
                telemetryKey={key}
                telemetryData={telemetryData}
                backgroundColor="white"
              />
            );
          })}
        </dl>
      </div>
    </>
  );
}

function IsFlag(name: string): boolean {
  return name === "FlowSw";
}
function FlagView({
  telemetryKey,
  telemetryData,
}: {
  telemetryKey: string;
  telemetryData: DatedIotData[];
}) {
  const data = telemetryData[telemetryData.length - 1].data[telemetryKey];
  const displayName = ParseTelemtryNames(telemetryKey);

  const HasFlow = data >= 300;

  return (
    <div className="px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-900">{displayName}</dt>
      <dd
        className={`mt-1 text-right text-sm font-bold leading-6 sm:col-span-1 sm:mt-0 ${
          HasFlow ? "text-green-500" : "text-red-500"
        }`}
      >
        {HasFlow ? "On" : "Off"}
      </dd>
    </div>
  );
}

function TelemetricView({
  telemetryKey,
  telemetryData,
  backgroundColor,
}: {
  telemetryKey: string;
  telemetryData: DatedIotData[];
  backgroundColor: string;
}) {
  const [ShowDetailed, SetShowDetailed] = React.useState<boolean>(false);
  const displayName = ParseTelemtryNames(telemetryKey);
  const HasNoDetailed = HasNoDetailedView(telemetryKey);

  const flag = IsFlag(telemetryKey);
  if (displayName === undefined) {
    return null;
  }
  if (flag) {
    return (
      <FlagView telemetryKey={telemetryKey} telemetryData={telemetryData} />
    );
  }

  if (HasNoDetailed) {
    return (
      <NormalView telemetryKey={telemetryKey} telemetryData={telemetryData} />
    );
  }
  if (!ShowDetailed) {
    return (
      <NormalView telemetryKey={telemetryKey} telemetryData={telemetryData} />
    );
  }

  return (
    <>
      <NormalView telemetryKey={telemetryKey} telemetryData={telemetryData} />

      {/* <DetailedView telemetryKey={telemetryKey} telemetryData={telemetryData} /> */}
    </>
  );
}

function NormalView({
  telemetryKey,
  telemetryData,
}: {
  telemetryKey: string;
  telemetryData: DatedIotData[];
}) {
  const data = telemetryData[telemetryData.length - 1].data[telemetryKey];
  const { encoding } = useEncodingState();
  const parsedValue_local = data;
  const local_unit = MapNameToUnit(telemetryKey);
  const displayName = convertTelemtryNameToEncoding(
    encoding,
    ParseTelemtryNames(telemetryKey),
  );

  const unit = convertNameToEncoding(encoding, local_unit);
  const parsedValue = convertValueToEncoding(
    encoding,
    parsedValue_local,
    local_unit,
  );

  return (
    <div className="px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-900">{displayName}</dt>
      <dd className="mt-1 text-right text-sm leading-6 text-gray-700 sm:col-span-1 sm:mt-0">
        {parsedValue} {unit}
      </dd>
    </div>
  );
}

