import React from "react";

export function InfoElement({
  name,
  value,
}: {
  name: string;
  value: string;
}): JSX.Element {
  
  return (
    <div className="px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-900">{name}</dt>
      <dd className="mt-1 text-right text-sm leading-6 text-gray-700 sm:col-span-1 sm:mt-0">
        {value}
      </dd>
    </div>
  );
}

