import axios, { AxiosResponse } from "axios";
import { DisconnectErrorType, ISocket } from "./Socket";
import {
  DatedHistoricIotData,
  DatedIotData,
  ParseUpTimeData,
  UpTimeSpan,
  convertHistoricIotData,
  staticData,
} from "./serverInterFace";
import errorMapFile from "shared/utils/errors.json";
import { FactorValue } from "../../Views/DashBoard/DashBoardParsing";
import { ParseBoosterType } from "../ParsingOfBoosterType";
import { ParseSettings, Settings } from "./Settings";
import { AttributesTable } from "./attributes";

const API_URL = process.env.REACT_APP_API_URL;
let server: HttpServer | null = null;

const serverUrl: string = API_URL || "http://localhost:8000";

export type Version = { major: number; minor: number; patch: number };
export function SendCommand(
  command: string,
  machineId: string,
  serviceCode: string,
  payload: string,
  callback: (
    data: Record<string, string>,
    err: Record<string, string> | null,
  ) => void,
): void {
  axios
    .get(
      `${serverUrl}/api/sendCommand/` +
        machineId +
        "/" +
        command +
        "/" +
        serviceCode +
        "/" +
        payload,
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("token") as string)
            .token,
        },
      },
    )
    .then((res) => {
      console.log(res.data.err);
      if (res.data.err === "Wrong service Code") {
        callback({}, { err: "Wrong service Code" });

        return;
      }
      if (res.data.err) {
        callback({}, { err: "Machine is offline" });
        return;
      }
      callback(res.data.result.payload, null);
    })
    .catch((err) => {
      if (err.request) {
        callback({}, { err: "Server is offline" });
      }
    });
  return;
}

export async function InitializeServer(
  socket: ISocket,
  machineId: string,
  serviceCode: string,
  version: Version,
  disconnectHandler: (err: DisconnectErrorType) => void,
): Promise<HttpServer> {
  server = new HttpServer(socket, machineId, serviceCode, version);

  server?.ListenForDisconnect(disconnectHandler);

  await server?.SetConfigFiles();
  await server?.setDataForLastDay();
  await server?.SetMachineType();
  await server?.setStaticData();

  return server;
}

export function GetServer(): HttpServer {
  return server!;
}

export function DeIntializeServer() {
  server?.socket?.removeListenerForDisconnect();
  server?.Disconnect();
  server = null;
}

export function ServerExist(): boolean {
  return server !== null;
}
export class HttpServer {
  socket?: ISocket;
  machineId: string;
  errorFile: Record<string, string>;
  configFile: Record<string, string>;
  latest10Min: DatedHistoricIotData[];
  latest24Hour: DatedHistoricIotData[];
  latest24HourTimeSpan: [number | null, UpTimeSpan[]];
  machineType: string | null;
  serviceCode: string;
  dataOrder?: string[];
  version: Version;
  staticData: staticData | null;
  constructor(
    socket: ISocket,
    machineId: string,
    serviceCode: string,
    version: Version,
  ) {
    this.socket = socket;
    this.machineId = machineId;
    this.configFile = {};
    this.errorFile = {};
    this.latest10Min = [];
    this.latest24Hour = [];
    this.latest24HourTimeSpan = [null, []];
    this.machineType = null;
    this.serviceCode = serviceCode;
    this.version = version;
    this.staticData = null;
  }

  getmahineId(): string {
    return this.machineId;
  }

  getControllerVersion(): Version {
    return this.version;
  }
  isVersionCompatible(version: Version): boolean {
    const MachineVersion = this.getControllerVersion();
    return (
      MachineVersion.major > version.major ||
      (MachineVersion.major >= version.major &&
        MachineVersion.minor > version.minor) ||
      (MachineVersion.major >= version.major &&
        MachineVersion.minor >= version.minor &&
        MachineVersion.patch >= version.patch)
    );
  }
  async GetErrorFile(): Promise<Record<string, any>> {
    return await this.FetchErrorFile();
  }
  async SetMachineType(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isVersionCompatible({ major: 2, minor: 1, patch: 5 })) {
        this.SendCommand("GetMachineType", null, (mabye_data, err) => {
          console.log(err);
          if (!err) {
            const data = mabye_data!;

            this.machineType = data.MachineType;
            resolve();
            return;
          }
          this.GetEepromSettings((mabye_data, err) => {
            if (!err) {
              const data = mabye_data![1];
              console.log("huh");
              this.machineType = ParseBoosterType(data).boosterCode;
              resolve();
            }
          });
          resolve();
        });
        return;
      }

      this.GetEepromSettings((mabye_data, err) => {
        if (!err) {
          const data = mabye_data![1];

          this.machineType = ParseBoosterType(data).boosterCode;
          resolve();
        }
      });
    });
  }

  Disconnect() {
    this.socket!.disconnect_no_error();
  }
  disconnect_with_reason(error: DisconnectErrorType) {
    this.socket!.disconnect_with_reason(error);
  }

  async GetConfigFile(): Promise<Record<string, any>> {
    return (await this.FetchConfigFile()).data;
  }
  async SetConfigFiles(): Promise<any> {
    // this.errorFile = (await this.FetchErrorFile()).data;

    this.configFile = (await this.FetchConfigFile()).data;

    this.errorFile = (await this.FetchConfigFile()).data;
  }
  async setDataForLastDay(): Promise<null> {
    return new Promise((resolve, reject) => {
      this.GetDataForLast10Min((data: any) => {
        let hour24Data = convertHistoricIotData(data);

        this.latest24Hour = hour24Data;
        let TenMinago = new Date();
        TenMinago.setMinutes(TenMinago.getMinutes() - 10);
        this.latest10Min = this.latest24Hour.filter((data) => {
          return data.date > TenMinago.valueOf();
        });
        this.latest10Min = Object.values(this.latest10Min).map((data) => {
          return Object.keys(data.data).reduce(
            (factored_data, key) => {
              factored_data.data[key] = FactorValue(key, data.data[key]);

              return factored_data;
            },
            { data: data.data, date: data.date },
          );
        });

        this.socket?.setLast10Min(this.latest10Min as DatedIotData[]);

        resolve(null);
      });
    });
  }

  getDataForLast10Min(): DatedHistoricIotData[] {
    return this.latest10Min;
  }
  getLatestData(): DatedIotData {
    return this.socket!.getLatestData();
  }

  async setStaticData(): Promise<{}> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${serverUrl}/api/GetStaticData/${this.machineId}/${this.serviceCode}/`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("token") as string)
                .token,
            },
          },
        )
        .then((res) => {
          if (res.data.err) {
            resolve({});
            return;
          }
          this.staticData = res.data.result.payload;
          resolve({});
        })
        .catch(() => {
          resolve({});
        });
    });
  }
  getStaticData(): staticData {
    return this.staticData!;
  }
  getDataForLast24Hour(): DatedHistoricIotData[] {
    return this.latest24Hour;
  }

  Ping(callback: (data: boolean, err: string | null) => void) {
    axios
      .get(`${serverUrl}/api/Ping` + this.machineId, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("token") as string)
            .token,
        },
      })
      .then((res) => {
        if (res.data.err) {
          callback(res.data.connection, res.data.err);
          return;
        }
        callback(res.data.connection, null);
      })
      .catch(() => {});
  }

  SendCommandRaw(
    command: string,
    payload: string | null,
    callback: (
      data: Record<string, string>,
      err: Record<string, string> | null,
    ) => void,
  ): void {
    if (payload == null) {
      axios
        .get(`${serverUrl}/api/sendCommand` + this.machineId + "/" + command, {
          // headers: {
          //     Authorization: JSON.parse(localStorage.getItem('token') as string).token
          // }
        })
        .then((res) => {
          if (res.data.err) {
            callback({}, res.data.err);
            return;
          }

          callback(res.data.result.payload, null);
        })
        .catch(() => {});
      return;
    }
  }

  SendCommand(
    command: string,
    payload: string | null,
    callback: (
      data: Record<string, string> | null,
      err: Record<string, string> | null,
    ) => void,
  ): void {
    if (payload == null) {
      axios
        .get(
          `${serverUrl}/api/sendCommand/` +
            this.machineId +
            "/" +
            command +
            "/" +
            this.serviceCode,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("token") as string)
                .token,
            },
          },
        )
        .then((res) => {
          if (res.data.err) {
            callback({}, res.data.err);
            return;
          }

          callback(res.data.result.payload, null);
        })
        .catch(() => {
          callback(null, { err: "didnt leave navigator mode" });
        });
      return;
    }
    axios
      .get(
        `${serverUrl}/api/sendCommand/` +
          this.machineId +
          "/" +
          command +
          "/" +
          this.serviceCode +
          "/" +
          payload,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("token") as string)
              .token,
          },
        },
      )
      .then((res) => {
        if (res.data.err) {
          callback({}, res.data.err);
          return;
        }
        callback(res.data.result.payload, null);
      })
      .catch(() => {
        callback(null, { err: "didnt leave navigator mode" });
      });
  }

  GetDataForLastDay(cb: (val: Record<string, string>[]) => void) {
    axios
      .get(
        `${serverUrl}/api/GetDataForLastDay/${this.machineId}/0/${this.serviceCode}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("token") as string)
              .token,
          },
        },
      )
      .then((res) => {
        cb(res.data);
      })
      .catch(() => {});
  }
  GetMachineAttributes(
    cb: (val: null | Record<string, Settings>, err: null | any) => void,
  ) {
    if (this.version)
      axios
        .get(
          `${serverUrl}/api/GetEepromSettingsAttribMemJSON/${this.machineId}/${this.serviceCode}/${this.machineType}/`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("token") as string)
                .token,
            },
          },
        )
        .then((res) => {
          if (res.data.err) {
            cb(AttributesTable, null);
            return;
          }

          cb(res.data.result.payload.AppSettingValsEepromType, null);
        })
        .catch(() => {});
  }
  GetMachineDefaultSettingsMemJSON(
    cb: (val: null | Record<string, string>, err: null | any) => void,
  ) {
    axios
      .get(
        `${serverUrl}/api/GetMachineDefaultSettingsMemJSON/${this.machineId}/${this.serviceCode}/${this.machineType}/`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("token") as string)
              .token,
          },
        },
      )
      .then((res) => {
        if (res.data.err) {
          cb(null, res.data.err);
        }

        cb(res.data.result.payload.AppSettingValsEepromType, null);
      })
      .catch(() => {});
  }

  GetDataForLast10Min(cb: (val: Record<string, string>[]) => void) {
    axios
      .get(
        `${serverUrl}/api/GetDataForLast10Min/${this.machineId}/${this.serviceCode}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("token") as string)
              .token,
          },
        },
      )
      .then((res) => {
        cb(res.data);
      })
      .catch(() => {});
  }
  /*SAFETY: Since this is ALWAYS called, before setting settings, its safe to set the settings here for now. */
  GetEepromSettings(
    cb: (
      val: null | [string[], Record<string, string>],
      err: null | any,
    ) => void,
  ) {
    axios
      .get(
        `${serverUrl}/api/GetEepromSettings/${this.machineId}/${this.serviceCode}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("token") as string)
              .token,
          },
        },
      )
      .then((res) => {
        if (res.data.err) {
          cb(null, res.data.err);
        }
        console.log(res.data.result.payload);
        if (res.data.result.payload.data) {
          this.dataOrder = res.data.data;

          cb([[], res.data.result.payload.data.AppSettingValsEepromType], null);
        }

        cb(
          [
            res.data.result.payload.orderedKeys,
            res.data.result.payload.AppSettingValsEepromType,
          ],
          null,
        );
      })
      .catch(() => {});
  }
  SetEepromSettings(
    payload: Record<string, string>,
    callback: (
      data: Record<string, string> | null,
      err: Record<string, string> | null,
    ) => void,
  ) {
    axios
      .post(
        `${serverUrl}/api/SetEepromSettings/${this.machineId}/${this.serviceCode}`,
        payload,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("token") as string)
              .token,
          },
        },
      )
      .then((res) => {
        if (res.data.err) {
          callback(null, res.data.err);
          return;
        }
        callback(res.data.ok, null);
      })
      .catch(() => {});
  }

  GetUpTimeData(
    callback: (
      data: UpTimeSpan[] | null,
      err: Record<string, string> | null,
    ) => void,
  ) {
    axios
      .get(
        `${serverUrl}/api/sendCommand/${this.machineId}/GetStartStopMachineJSON/${this.serviceCode}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("token") as string)
              .token,
          },
        },
      )
      .then((res) => {
        if (res.data.err) {
          callback(null, res.data.err);
          return;
        }
        callback(ParseUpTimeData(res.data.result.payload.StartStopInfo), null);
      });
  }

  async FetchErrorFile(): Promise<Record<string, any>> {
    return errorMapFile as Record<string, any>;
  }

  async FetchConfigFile(): Promise<AxiosResponse<Record<string, any>>> {
    let response: Promise<AxiosResponse<Record<string, any>>> = axios
      .get(`${serverUrl}/api/GetConfig/${this.machineId}/${this.serviceCode}`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("token") as string)
            .token,
        },
      })
      .catch(() => {
        return new Promise((res, rej) => {
          res({
            data: {},

            status: 200,

            statusText: "OK",

            headers: {},

            config: {},

            request: {},
          });
        });
      });
    return response;
  }
  async GetErrors(): Promise<AxiosResponse<Record<string, number>>> {
    let response: Promise<AxiosResponse<Record<string, number>>> = axios
      .get(`${serverUrl}/api/GetErrors/${this.machineId}/${this.serviceCode}`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("token") as string)
            .token,
        },
      })
      .catch(() => {
        return new Promise((res, rej) => {
          res({
            data: {},

            status: 200,

            statusText: "OK",

            headers: {},

            config: {},

            request: {},
          });
        });
      });
    return response;
  }
  machineIsRebooting(): boolean {
    if (this.socket) {
      this.socket.isRebooting();
    }
    return false;
  }
  on_telemtry(cb: (parsed_data: DatedIotData) => void) {
    this.socket?.on(`telemetry-${this.machineId}`, cb);
  }
  on_telemtry_last_10_min(cb: (parsed_data: DatedIotData[]) => void) {
    this.socket?.on_last_10_min(`telemetry-${this.machineId}`, cb);
  }
  off_telemtry_last_10_min(cb: (parsed_data: DatedIotData[]) => void) {
    this.socket?.off_last_10_min(`telemetry-${this.machineId}`, cb);
  }
  off_telemtry(cb: (parsed_data: DatedIotData) => void) {
    this.socket?.off(`telemetry-${this.machineId}`, cb);
  }
  on(eventName: string, cb: (parsed_data: DatedIotData) => void) {
    this.socket?.on(eventName, (telemtryData) => {
      cb(telemtryData);
    });
  }
  off(eventName: string, cb: (parsed_data: DatedIotData) => void) {
    this.socket?.off(eventName, cb);
  }
  ListenForDisconnect(disconnect_handler: (err: DisconnectErrorType) => void) {
    this.socket?.ListenForDisconnect(disconnect_handler);
  }
  toogleListeningForDisconnect() {
    this.socket?.toogleListeningForDisconnect();
  }
  disconnect() {
    this.socket?.disconnect();
  }
  disconnectNoError() {
    this.socket?.disconnect_no_error();
  }
}
