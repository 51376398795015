import { Box, Button, Grid, List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import {
  DatedIotData,
  IotData,
} from "../../Models/ServerCommuncation/serverInterFace";
import { ProgressCircle } from "../utils";
const DIGITAL_DIODE_INPUT_NAMES = ["DI1", "DI2", "DI3", "DI4", "DI5", "DI6"];
const ANALOG_INPUT_DIODE = ["AI1", "AI2"];

const DIODE_OUTPUT_NAMES = [
  "DO1",
  "DO2",
  "DO3",
  "DO4",
  "DO5",
  "DO6",
  "DO7",
  "DO8",
  "DO9",
  "RL1",
  "RL2",
];

const INVERTER_INPUT_NAMES = [
  "PFI1",
  "PFI2",
  "PFI3",
  "PFI4",
  "GFI1",
  "GFI2",
  "GFI3",
  "GFI4",
];
const INVERTER_OUTPUT_NAMES = ["RL1", "RL2", "DO1", "DO2"];

type Diode<T> = { diode_value: T; diode_name: string };

type DiodeSourceType = "Display" | "Inverter";

type DiodeType = "Output" | "Input";
type DiodeViews = () => JSX.Element;

const DiodesDisplayViews: Record<DiodeType, DiodeViews> = {
  Output: DisplayOutputDiodes,
  Input: DisplayInputDiodes,
};

const DiodesInverterViews: Record<DiodeType, DiodeViews> = {
  Output: DisplayInverterOutput,
  Input: DisplayInverterInput,
};

const DiodeSourceTypeViews: Record<
  DiodeSourceType,
  Record<DiodeType, DiodeViews>
> = {
  Display: DiodesDisplayViews,
  Inverter: DiodesInverterViews,
};

function GreenLED(): JSX.Element {
  return (
    <Box
      height={"1.2vmax"}
      width={"1.2vmax"}
      className={"green led"}
      borderColor={"silver"}
      borderRadius={"50%"}
      border={"0.2vmax solid #cecccc"}
    ></Box>
  );
}

function GrayLED(): JSX.Element {
  return (
    <Box
      height={"1.2vmax"}
      width={"1.2vmax"}
      className={"gray led"}
      borderColor={"silver"}
      borderRadius={"50%"}
      border={"0.2vw solid #cecccc"}
    ></Box>
  );
}

export default function DiodeBox(): JSX.Element {
  const [SourceViewChosen, SetSourceViewChosen] =
    React.useState<DiodeSourceType>("Display");

  const [diodeViewChosen, SetDiodeViewChosen] =
    React.useState<DiodeType>("Output");

  const DiodeView = DiodeSourceTypeViews[SourceViewChosen][diodeViewChosen];
  return (
    <div className="mt-4 flex flex-col overflow-hidden">
      <DiodeMenuSelector
        diodeViewChosen={SourceViewChosen}
        SetDiodeViewChosen={SetSourceViewChosen}
        Choices={["Display", "Inverter"]}
      ></DiodeMenuSelector>
      <div className="hidden w-full p-[0.1em] lg:block" aria-hidden="true" />

      <DiodeMenuSelector
        diodeViewChosen={diodeViewChosen}
        SetDiodeViewChosen={SetDiodeViewChosen}
        Choices={["Output", "Input"]}
      ></DiodeMenuSelector>
      <div className="hidden w-full p-[0.1em] lg:block" aria-hidden="true" />

      <div className="flex h-[21em] flex-grow ">
        <DiodeView></DiodeView>
      </div>
    </div>
  );
}

function DiodeMenuSelector<T>({
  diodeViewChosen,
  SetDiodeViewChosen,
  Choices,
}: {
  Choices: [T, T];
  diodeViewChosen: T;
  SetDiodeViewChosen: React.Dispatch<React.SetStateAction<T>>;
}): JSX.Element {
  return (
    <div className=" h-full   flex-col ">
      <div className="flex h-full">
        <div className="  flex w-full flex-grow">
          <DiodeMenuButton
            name={Choices[0]}
            DiodeMenu={diodeViewChosen}
            onClick={() => {
              SetDiodeViewChosen(Choices[0]);
            }}
          ></DiodeMenuButton>
        </div>
        <div
          className="ml-1 mr-1  flex  basis-1 bg-gray-400 p-[0.1em] lg:block"
          aria-hidden="true"
        />
        <div className="flex w-full flex-grow">
          <DiodeMenuButton
            name={Choices[1]}
            DiodeMenu={diodeViewChosen}
            onClick={() => {
              SetDiodeViewChosen(Choices[1]);
            }}
          ></DiodeMenuButton>
        </div>
      </div>
    </div>
  );
}

function DiodeMenuButton<T>({
  name,
  DiodeMenu,
  onClick,
}: {
  name: T;
  DiodeMenu: T;
  onClick: () => void;
}): JSX.Element {
  if (DiodeMenu === name) {
    return (
      <Grid item xs bgcolor={"#001b54"}>
        <Button fullWidth onClick={onClick}>
          <Typography color={"white"} variant="button">
            {name}
          </Typography>
        </Button>
      </Grid>
    );
  }
  return (
    <Grid item xs>
      <Button fullWidth onClick={onClick}>
        {name}
      </Button>
    </Grid>
  );
}

function ParseOutPutDiodes(data: IotData): [Diode<boolean>, Diode<boolean>?][] {
  return ParseDiodes(
    data.DiodesState,
    16,
    DIODE_OUTPUT_NAMES,
    (state, idx, shift) => {
      return [
        !!((state >> (idx + shift)) & 1),
        !!((state >> (idx + shift + 1)) & 1),
      ];
    },
  );
}

function ParseAnalogInPutDiodes(
  data: IotData,
): [Diode<number>, Diode<number>?][] {
  const ToVoltValue = 0.0030625;
  return ParseDiodes(
    data.AnalogState,
    16,
    ANALOG_INPUT_DIODE,
    (state, idx, shift) => {
      return [
        (state & 0xff) * ToVoltValue,
        ((state >> shift) & 0xff) * ToVoltValue,
      ];
    },
  );
}

function ParseInPutDiodes(data: IotData): [Diode<boolean>, Diode<boolean>?][] {
  let inputShift = 0;
  return ParseDiodes(
    data.DiodesState,
    inputShift,
    DIGITAL_DIODE_INPUT_NAMES,
    (state, idx, shift) => {
      return [
        !!((state >> (idx + shift)) & 1),
        !!((state >> (idx + shift + 1)) & 1),
      ];
    },
  );
}

function ParseInverterInput(
  data: IotData,
): [Diode<boolean>, Diode<boolean>?][] {
  let first_part = ParseDiodes(
    data.InvPortInputState,
    0,
    INVERTER_INPUT_NAMES.slice(0, 4),
    (state, idx, shift) => {
      return [
        !((state >> (idx + shift)) & 1),
        !((state >> (idx + shift + 1)) & 1),
      ];
    },
  );
  let second_part = ParseDiodes(
    data.InvPortInputState,
    4,
    INVERTER_INPUT_NAMES.slice(4, INVERTER_INPUT_NAMES.length),
    (state, idx, shift) => {
      return [
        !!((state >> (idx + shift)) & 1),
        !!((state >> (idx + shift + 1)) & 1),
      ];
    },
  );
  return first_part.concat(second_part);
}
function ParseInverterOutput(
  data: IotData,
): [Diode<boolean>, Diode<boolean>?][] {
  return ParseDiodes(
    data.InvPortOutputState,
    0,
    INVERTER_OUTPUT_NAMES,
    (state, idx, shift) => {
      return [
        !!((state >> (idx + shift)) & 1),
        !!((state >> (idx + shift + 1)) & 1),
      ];
    },
  );
}

function ParseDiodes<T>(
  value: number,
  shift: number,
  diode_names: string[],
  calcValueFn: (state: number, idx: number, shift: number) => [T, T],
): [Diode<T>, Diode<T>?][] {
  let diodes: [Diode<T>, Diode<T>?][] = [];

  let idx = 0;

  for (; idx <= diode_names.length - 2; idx += 2) {
    let [diode_val1, diode_val2] = calcValueFn(value, idx, shift);
    let diode1: Diode<T> = {
      diode_value: diode_val1,
      diode_name: diode_names[idx],
    };
    let diode2: Diode<T> = {
      diode_value: diode_val2,
      diode_name: diode_names[idx + 1],
    };

    diodes.push([diode1, diode2]);
  }

  if ((diode_names.length & 1) === 1) {
    let [diode_val] = calcValueFn(value, idx, shift);
    let diode: Diode<T> = {
      diode_value: diode_val,
      diode_name: diode_names[idx],
    };
    diodes.push([diode, undefined]);
  }

  return diodes;
}

function DisplayOutputDiodes(): JSX.Element {
  const [Diodes, SetDiodes] = React.useState<
    [Diode<boolean>, Diode<boolean>?][]
  >([]);
  const Server = GetServer();

  const ParseAndSetdiodes = (data: DatedIotData) => {
    let diodes = ParseOutPutDiodes(data.data);
    SetDiodes(diodes);
  };

  useEffect(() => {
    Server.on_telemtry(ParseAndSetdiodes);
    return () => Server.off_telemtry(ParseAndSetdiodes);
  }, [Server]);

  if (Diodes.length === 0) {
    return <ProgressCircle />;
  }
  return <DiodeListView diodes={Diodes}></DiodeListView>;
}

function DiodeListView({
  diodes,
}: {
  diodes: [Diode<boolean>, Diode<boolean>?][];
}): JSX.Element {
  return (
    <div className="scroll flex  min-w-full flex-shrink flex-col  justify-evenly overflow-y-scroll ">
      {diodes.map((diode) => {
        return (
          <div className=" flex grid-cols-2  ">
            <DiodeView diode={diode[0]}></DiodeView>
            <DiodeView diode={diode[1]}></DiodeView>
          </div>
        );
      })}
    </div>
  );
}

function DisplayInverterOutput(): JSX.Element {
  const [Diodes, SetDiodes] = React.useState<
    [Diode<boolean>, Diode<boolean>?][]
  >([]);
  const Server = GetServer();
  const ParseAndSetdiodes = (data: DatedIotData) => {
    let diodes = ParseInverterOutput(data.data);
    SetDiodes(diodes);
  };
  useEffect(() => {
    Server.on_telemtry(ParseAndSetdiodes);
    return () => Server.off_telemtry(ParseAndSetdiodes);
  }, [Server]);

  if (Diodes.length === 0) {
    return <ProgressCircle />;
  }
  return <DiodeListView diodes={Diodes}></DiodeListView>;
}

function DisplayInverterInput(): JSX.Element {
  const [Diodes, SetDiodes] = React.useState<
    [Diode<boolean>, Diode<boolean>?][]
  >([]);
  const Server = GetServer();
  const ParseAndSetdiodes = (data: DatedIotData) => {
    console.log(data);

    let diodes = ParseInverterInput(data.data);
    SetDiodes(diodes);
  };
  useEffect(() => {
    Server.on_telemtry(ParseAndSetdiodes);
    return () => Server.off_telemtry(ParseAndSetdiodes);
  }, [Server]);

  if (Diodes.length === 0) {
    return <ProgressCircle />;
  }
  return <DiodeListView diodes={Diodes}></DiodeListView>;
}

function DisplayInputDiodes(): JSX.Element {
  const [Diodes, SetDiodes] = React.useState<
    [Diode<boolean>, Diode<boolean>?][]
  >([]);
  const [analogDiodes, SetAnalogDiodes] = React.useState<
    [Diode<number>, Diode<number>?][]
  >([]);
  const Server = GetServer();
  const ParseAndSetdiodes = (data: DatedIotData) => {
    let diodes = ParseInPutDiodes(data.data);
    let analogDiodes = ParseAnalogInPutDiodes(data.data);
    SetDiodes(diodes);
    SetAnalogDiodes(analogDiodes);
  };
  useEffect(() => {
    Server.on_telemtry(ParseAndSetdiodes);
    return () => Server.off_telemtry(ParseAndSetdiodes);
  }, [Server]);
  if (Diodes.length === 0) {
    return <ProgressCircle />;
  }
  return (
    <div className="scroll flex  min-w-full flex-shrink flex-col  justify-evenly overflow-y-scroll ">
      {Diodes.map((diode) => {
        return (
          <div className=" flex grid-cols-2   content-center">
            <DiodeView diode={diode[0]}></DiodeView>
            <DiodeView diode={diode[1]}></DiodeView>
          </div>
        );
      })}
      {analogDiodes.map((diode) => {
        return (
          <div className=" flex grid-cols-2   content-center ">
            <AnalogDiodeView analogDiode={diode[0]}></AnalogDiodeView>

            <AnalogDiodeView analogDiode={diode[1]}></AnalogDiodeView>
          </div>
        );
      })}
    </div>
  );
}
function AnalogDiodeView({
  analogDiode,
}: {
  analogDiode?: Diode<number>;
}): JSX.Element | null {
  console.log(analogDiode);
  if (analogDiode === undefined) {
    return null;
  }

  return (
    <div className="flex flex-grow grid-cols-3 content-center justify-center gap-x-1">
      <div className="flex items-center justify-center">
        <Typography
          variant="button"
          color={"black"}
          alignItems={"center"}
          textAlign={"center"}
        >
          {analogDiode.diode_name}
        </Typography>
      </div>

      <div className="flex content-center items-center  justify-center">
        <Typography
          variant="button"
          color={"black"}
          alignItems={"center"}
          textAlign={"center"}
        >
          {analogDiode.diode_value}
        </Typography>
      </div>
      <div className="flex content-center items-center  justify-center">
        <Typography
          variant="button"
          color={"black"}
          alignItems={"center"}
          textAlign={"center"}
        >
          V
        </Typography>
      </div>
    </div>
  );
}

function DiodeView({ diode }: { diode?: Diode<boolean> }): JSX.Element | null {
  if (diode === undefined) {
    return (
      <div className="flex flex-grow basis-1/2 grid-cols-2">
        <div className="flex  items-center justify-center"></div>
        <div className="flex flex-grow  items-center justify-center"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-grow basis-1/2 grid-cols-2">
      <div className="flex  basis-1/2 items-center justify-center">
        <Typography
          variant="button"
          color={"black"}
          alignItems={"center"}
          textAlign={"center"}
        >
          {diode.diode_name}
        </Typography>
      </div>

      <div className="flex flex-grow  basis-1/2 items-center justify-center">
        {diode.diode_value ? <GreenLED></GreenLED> : <GrayLED></GrayLED>}
      </div>
    </div>
  );
}
