import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Button as ButtonModel, UiElement } from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";
import {
  convertNameToEncoding,
  convertValueToEncoding,
  useEncodingState,
} from "pages/Navigator/Models/DataEncoding";

export default function Button({
  element,
  ScreenUpdate,
  disabled,
}: {
  element: UiElement;
  ScreenUpdate?: (ignoreUpdateCheck?: boolean) => void;
  disabled: boolean;
}): JSX.Element {
  return (
    <InnerButton
      button={element as ButtonModel}
      ScreenUpdate={ScreenUpdate}
      disabled={disabled}
    ></InnerButton>
  );
}

function ButtonWrapper({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}): JSX.Element {
  return (
    <div
      className={`flex h-[7em]  w-[7em] content-center justify-center    border-[white] bg-[darkblue] text-[1em] sm:rounded sm:border md:border-2 lg:border-2 xl:border-2 2xl:border-2`}
    >
      {children}
    </div>
  );
}

function InnerButton({
  button,
  ScreenUpdate,
  disabled,
}: {
  button: ButtonModel;
  ScreenUpdate?: () => void;
  disabled: boolean;
}) {
  if (button.imageFile) return <ImgButton button={button}></ImgButton>;
  if (button.telemtryField)
    return <TelemtryButton button={button}></TelemtryButton>;
  if (button.name === "Reset") {
    return (
      <LastResetCommandButton
        button={button}
        ScreenUpdate={ScreenUpdate}
        disabled={disabled}
      ></LastResetCommandButton>
    );
  }
  if (button.command)
    return (
      <CommandButton
        button={button}
        ScreenUpdate={ScreenUpdate}
        disabled={disabled}
      ></CommandButton>
    );
  console.log(button.name);

  return (
    <ButtonWrapper>
      <button>
        <p className="cursor-default text-[1em] text-white">
          {button.display_name}
        </p>
      </button>
    </ButtonWrapper>
  );
}

function ImgButton({ button }: { button: ButtonModel }) {
  return (
    <button>
      <img
        src={"shared/uitls/images/" + button!.imageFile}
        alt={button.display_name}
      />
      {button.display_name}
    </button>
  );
}

function TelemtryButton({ button }: { button: ButtonModel }) {
  const Server = GetServer();

  const LatestData = Server.getLatestData();
  console.log(LatestData);
  let [val, setVal] = React.useState<number>(
    LatestData.data[button.telemtryField!],
  );

  const { encoding } = useEncodingState();
  const unit = convertNameToEncoding(encoding, button.display_name);
  function SetTelemetry(data: DatedIotData) {
    let local_val = data.data[button.telemtryField!]
      ? data.data[button.telemtryField!]
      : 0;
    let val = convertValueToEncoding(encoding, local_val, button.display_name);
    setVal(val);
  }
  useEffect(() => {
    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, []);

  // if (button.highlightable) {

  // }

  return (
    <ButtonWrapper>
      <button>
        <p className="text-[1em]">{button.display_name}</p>
      </button>
    </ButtonWrapper>
  );
}

function CommandButton({
  button,
  ScreenUpdate,
  disabled,
}: {
  button: ButtonModel;
  ScreenUpdate?: () => void;
  disabled: boolean;
}) {
  const server = GetServer();
  function sendCommand() {
    if (disabled) {
      return;
    }
    let name = button.command!.name;
    let arg_val = button.command!.arg_val;

    if (arg_val) {
      server.SendCommand(`${name}`, `${arg_val}`, () => {});

      return;
    }

    server.SendCommand(name, null, () => {});
  }
  return (
    <ButtonWrapper>
      <div
        className="flex h-full w-full content-center justify-center"
        style={{ cursor: disabled ? "default" : "pointer" }}
      >
        <button
          onClick={() => {
            if (disabled) {
              return;
            }

            sendCommand();
            ScreenUpdate?.();
          }}
          className=" text-white"
        >
          <p className="text-[1em]">{button.display_name}</p>
        </button>
      </div>
    </ButtonWrapper>
  );
}

function LastResetCommandButton({
  button,
  ScreenUpdate,
  disabled,
}: {
  button: ButtonModel;
  ScreenUpdate?: (ignoreUpdateCheck?: boolean) => void;
  disabled: boolean;
}) {
  console.log("lastreset");
  const server = GetServer();
  function sendCommand() {
    if (disabled) {
      return;
    }
    let name = button.command!.name;
    let arg_val = button.command!.arg_val;

    if (arg_val) {
      server.SendCommand(`${name}`, `${arg_val}`, async () => {
        await server.setStaticData();
        ScreenUpdate?.();
      });

      return;
    }

    server.SendCommand(name, null, () => {});
  }
  return (
    <ButtonWrapper>
      <div
        className="flex h-full w-full content-center justify-center"
        style={{ cursor: disabled ? "default" : "pointer" }}
      >
        <button
          onClick={async () => {
            if (disabled) {
              return;
            }
            ScreenUpdate?.(true);

            sendCommand();
          }}
          className=" text-white"
        >
          <p className="text-[1em]">{button.display_name}</p>
        </button>
      </div>
    </ButtonWrapper>
  );
}
