import React, { useEffect } from "react";

import { Grid, Typography } from "@mui/material";
import { UiElement, InfoField as infoFieldModel } from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";
import {
  convertNameToEncoding,
  convertValueToEncoding,
  useEncodingState,
} from "pages/Navigator/Models/DataEncoding";

export default function InfoField({
  element,
  ScreenUpdate,
  disabled,
}: {
  element: UiElement;
  ScreenUpdate?: () => void;
  disabled: boolean;
}) {
  return <InnerInfoField infoField={element}></InnerInfoField>;
}

function InfoFieldWrapper({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}): JSX.Element {
  return (
    <div className=" flex h-full flex-grow flex-wrap   justify-center pb-1.5 pt-1.5  text-[length:inherit] ">
      {children}
    </div>
  );
}

function InnerInfoField({ infoField }: { infoField: infoFieldModel }) {
  if (infoField.telemtryField) {
    return (
      <TelemetryInfoField
        infoField={infoField}
        telemtryField={infoField.telemtryField}
      ></TelemetryInfoField>
    );
  }

  return (
    <InfoFieldWrapper>
      <div className="bottom-0 flex basis-6/12 justify-center">
        <p className="bottom-0  text-center text-[1em] text-[white]">
          {infoField.display_name}
        </p>
      </div>
      <div className="auto-top bottom-0 flex  h-full basis-6/12 justify-start bg-white  ">
        <p className="bottom-0 text-center text-[1em] text-black">
          {infoField.fieldVal}
        </p>
      </div>
    </InfoFieldWrapper>
  );
  //default case
}

function TelemetryInfoField({
  infoField,
  telemtryField,
}: {
  infoField: infoFieldModel;
  telemtryField: string;
}) {
  const Server = GetServer();
  const LatestData = Server.getLatestData();
  let [val, setVal] = React.useState<number>(
    LatestData.data[infoField.telemtryField!],
  );
  const { encoding } = useEncodingState();
  useEffect(() => {
    function SetTelemetry(data: DatedIotData) {
      if (infoField.fieldUnit !== undefined) {
        setVal(
          convertValueToEncoding(
            encoding,
            data.data[telemtryField],
            infoField.fieldUnit,
          ),
        );
        return;
      }
      setVal(data.data[telemtryField]);
    }
    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, [Server, infoField, telemtryField]);
  if (infoField.fieldUnit) {
    return (
      <InfoFieldWrapper>
        <div className="bottom-0 flex  basis-6/12">
          {" "}
          <p className="bottom-0 flex h-full items-center text-[1em] text-white ">
            {infoField.display_name}{" "}
          </p>
        </div>
        <div className="bottom-0 top-auto  flex h-full  basis-3/12 justify-center bg-white">
          {" "}
          <p className="bottom-0 flex h-full items-center text-center  text-[1em] text-[darkblue] ">
            {val}{" "}
          </p>
        </div>
        <div className="bottom-0 top-auto  flex basis-3/12  justify-start pl-[0.5vmax] ">
          {" "}
          <p className="bottom-0 flex h-full items-center text-[1em] text-white ">
            {convertNameToEncoding(encoding, infoField.fieldUnit)}{" "}
          </p>
        </div>
      </InfoFieldWrapper>
    );
  }
  return (
    <InfoFieldWrapper>
      <div className="bottom-0 flex h-full basis-6/12 items-center justify-center">
        <p className="bottom-0 text-center text-[1em]">
          {infoField.display_name}
        </p>
      </div>
      <div className="auto-top bottom-0 flex h-full basis-6/12 items-center justify-center bg-white ">
        <p className="bottom-0 text-center text-[1em] text-black">{val}</p>
      </div>
    </InfoFieldWrapper>
  );
}
