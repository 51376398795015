import React from "react";

export function ProgressCircle(): JSX.Element {
  return (
    <div className="flex h-full w-full flex-col content-center justify-center ">
      <div className={`flex  w-full justify-center `}>
        <Circle></Circle>
      </div>
    </div>
  );
}

function Circle() {
  return (
    <div className="flex flex-auto flex-col items-center justify-center p-4 md:p-5">
      <div className="flex justify-center">
        <div
          className="inline-block size-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600 dark:text-blue-500"
          role="status"
          aria-label="loading"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
}
