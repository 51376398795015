import React, { useEffect } from "react";
import {
  BoosterType,
  ParseBoosterType,
} from "../../Models/ParsingOfBoosterType";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { InfoElement as InfoElementView } from "./InfoElement";

import { ProgressCircle } from "../utils";
import DisplayInverters from "./TripCounterBox";

type InfoElement = { name: string; value: string | number };

export default function InfoBox(): JSX.Element {
  const [infoElements, setInfoElements] = React.useState<InfoElement[]>([]);
  const [totalTripCounters, setTotalTripCounters] =
    React.useState<TotalTripCounter | null>(null);

  const [boosterType, setBoosterType] = React.useState<BoosterType | null>(
    null,
  );

  const server = GetServer();

  useEffect(() => {
    if (infoElements.length !== 0) {
      return;
    }

    server.SendCommand("GetSwVersionsJSON", null, (data, err) => {
      if (!err) {
        let infoElements = createInfoElements(data!);
        setInfoElements(infoElements);
        console.log("infoElements", infoElements);
      }
    });
  }, [infoElements, server]);

  useEffect(() => {
    if (totalTripCounters !== null) {
      return;
    }
    server.SendCommand("GetTotalCountersJSON", null, (data, err) => {
      if (!err) {
        let totalTripCounters = data as any as TotalTripCountersData;
        setTotalTripCounters(getTotalTripCounter(totalTripCounters));
      }
    });
  }, [totalTripCounters, server]);

  useEffect(() => {
    if (boosterType !== null) {
      return;
    }
    server.GetEepromSettings((mabye_data, err) => {
      if (!err) {
        const data = mabye_data![1];
        setBoosterType(ParseBoosterType(data));
      }
    });
  }, [boosterType, server]);
  if ( infoElements.length === 0 || !totalTripCounters) {
    return (
      <div>
        <div className="grid auto-rows-min">
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="flex justify-between px-4 py-3 sm:px-6">
                <dt className="h-4 w-40 rounded bg-gray-200 dark:bg-neutral-700"></dt>
                <dd className="h-4 w-24 rounded bg-gray-200 text-right dark:bg-neutral-700"></dd>
              </div>
            </dl>
            <dl className="divide-y divide-gray-100">
              <div className="flex justify-between px-4 py-3 sm:px-6">
                <dt className="h-4 w-40 rounded bg-gray-200 dark:bg-neutral-700"></dt>
                <dd className="h-4 w-24 rounded bg-gray-200 text-right dark:bg-neutral-700"></dd>
              </div>
            </dl>
            <dl className="divide-y divide-gray-100">
              <div className="flex justify-between px-4 py-3 sm:px-6">
                <dt className="h-4 w-40 rounded bg-gray-200 dark:bg-neutral-700"></dt>
                <dd className="h-4 w-24 rounded bg-gray-200 text-right dark:bg-neutral-700"></dd>
              </div>
            </dl>
            <dl className="divide-y divide-gray-100">
              <div className="flex justify-between px-4 py-3 sm:px-6">
                <dt className="h-4 w-40 rounded bg-gray-200 dark:bg-neutral-700"></dt>
                <dd className="h-4 w-24 rounded bg-gray-200 text-right dark:bg-neutral-700"></dd>
              </div>
            </dl>
            <dl className="divide-y divide-gray-100">
              <div className="flex justify-between px-4 py-3 sm:px-6">
                <dt className="h-4 w-40 rounded bg-gray-200 dark:bg-neutral-700"></dt>
                <dd className="h-4 w-24 rounded bg-gray-200 text-right dark:bg-neutral-700"></dd>
              </div>
            </dl>
            <dl className="divide-y divide-gray-100">
              <div className="flex justify-between px-4 py-3 sm:px-6">
                <dt className="h-4 w-40 rounded bg-gray-200 dark:bg-neutral-700"></dt>
                <dd className="h-4 w-24 rounded bg-gray-200 text-right dark:bg-neutral-700"></dd>
              </div>
            </dl>
            <dl className="divide-y divide-gray-100">
              <div className="flex justify-between px-4 py-3 sm:px-6">
                <dt className="h-4 w-40 rounded bg-gray-200 dark:bg-neutral-700"></dt>
                <dd className="h-4 w-24 rounded bg-gray-200 text-right dark:bg-neutral-700"></dd>
              </div>
            </dl>
            <dl className="divide-y divide-gray-100">
              <div className="flex justify-between px-4 py-3 sm:px-6">
                <dt className="h-4 w-40 rounded bg-gray-200 dark:bg-neutral-700"></dt>
                <dd className="h-4 w-24 rounded bg-gray-200 text-right dark:bg-neutral-700"></dd>
              </div>
            </dl>
            <dl className="divide-y divide-gray-100">
              <div className="flex justify-between px-4 py-3 sm:px-6">
                <dt className="h-4 w-40 rounded bg-gray-200 dark:bg-neutral-700"></dt>
                <dd className="h-4 w-24 rounded bg-gray-200 text-right dark:bg-neutral-700"></dd>
              </div>
            </dl>
            <dl className="divide-y divide-gray-100">
              <div className="flex justify-between px-4 py-3 sm:px-6">
                <dt className="h-4 w-40 rounded bg-gray-200 dark:bg-neutral-700"></dt>
                <dd className="h-4 w-24 rounded bg-gray-200 text-right dark:bg-neutral-700"></dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    );
  }
  if (boosterType) {
    return (
      <div>
        <div className="grid auto-rows-min">
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <InfoElementView
                name="Serial Number"
                value={server.getmahineId()}
              />

              <InfoElementView
                name="Booster Type"
                value={boosterType.boosterType}
              />

              <InfoElementView
                name="Machine Code"
                value={boosterType.boosterCode}
              />

              {infoElements.map((infoElement) => {
                return (
                  <InfoElementView
                    name={infoElement.name}
                    value={infoElement.value.toString()}
                  />
                );
              })}
              <InfoElementView
                name="Install Time"
                value={totalTripCounters["Install Time"]}
              />

              <InfoElementView
                name="Last Reset Trip Time"
                value={totalTripCounters["Last Reset Trip Time"]}
              />
            </dl>
          </div>
          <div className="">
            <hr />
            <DisplayInverters />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="mt-4 rounded-2xl bg-white shadow-sm ring-1 ring-gray-900/5">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Machine Information
          </h3>
        </div>
        {/*  */}
        <div className="flex animate-pulse">
          <div className="shrink-0">
            <span className="block size-12 rounded-full bg-gray-200 dark:bg-neutral-700"></span>
          </div>

          <div className="ms-4 mt-2 w-full">
            <ul className="mt-5 space-y-3">
              <li className="h-4 w-full rounded-full bg-gray-200 dark:bg-neutral-700"></li>
              <li className="h-4 w-full rounded-full bg-gray-200 dark:bg-neutral-700"></li>
              <li className="h-4 w-full rounded-full bg-gray-200 dark:bg-neutral-700"></li>
              <li className="h-4 w-full rounded-full bg-gray-200 dark:bg-neutral-700"></li>
            </ul>
          </div>
        </div>
        {/*  */}
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <InfoElementView name="DeviceId" value={server.getmahineId()} />
            {infoElements.map((infoElement) => {
              return (
                <InfoElementView
                  name={infoElement.name}
                  value={infoElement.value.toString()}
                />
              );
            })}
          </dl>
        </div>
      </div>
    </>
  );
}

type TotalTripCountersData = {
  "Install Time": string;
  "Last Reset Trip Time": string;
};

type TotalTripCounter = {
  "Install Time": string;
  "Last Reset Trip Time": string;
};

function createInfoElements(data: Record<string, string>): InfoElement[] {
  return Object.keys(data)
    .filter((key) => {
      return data[key] !== "NA";
    })
    .map((key) => {
      return { name: key, value: data[key] as string };
    });
}

function getTotalTripCounter(
  TotalTripCounters: TotalTripCountersData,
): TotalTripCounter {
  const { "Install Time": omit1, "Last Reset Trip Time": omit2 } =
    TotalTripCounters;

  return { "Install Time": omit1, "Last Reset Trip Time": omit2 };
}
