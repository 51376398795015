import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  BigInfofield as BigInfoFieldModel,
  UiElement,
} from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";
import {
  convertNameToEncoding,
  convertValueToEncoding,
  useEncodingState,
} from "pages/Navigator/Models/DataEncoding";

export default function BigInfofield({
  element,
  ScreenUpdate,
  disabled,
}: {
  element: UiElement;
  ScreenUpdate?: () => void;
  disabled: boolean;
}) {
  return (
    <BigInfoFieldInner
      infoField={element as BigInfoFieldModel}
    ></BigInfoFieldInner>
  );
}

function BigInfoFieldInner({ infoField }: { infoField: BigInfoFieldModel }) {
  const Server = GetServer();
  const LatestData = Server.getLatestData();
  let [val, setVal] = React.useState<number>(
    LatestData.data[infoField.telemtryField!],
  );
  const { encoding } = useEncodingState();
  useEffect(() => {
    function SetTelemetry(data: DatedIotData) {
      if (infoField.fieldUnit !== undefined) {
        setVal(
          convertValueToEncoding(
            encoding,
            data.data[infoField.telemtryField],
            infoField.fieldUnit,
          ),
        );
        return;
      }
      setVal(data.data[infoField.telemtryField]);
    }
    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, [Server, infoField, encoding]);
  return (
    <div className="length:text- col-span-2 flex h-full w-full flex-wrap content-end justify-center">
      <div className="fle justify-center text-[length:inherit]">
        <p className="text-[4em] font-semibold text-white">{val}</p>
      </div>
      <div className="flex justify-center text-[length:inherit]">
        <p className="text-[4em] font-semibold text-white">
          {convertNameToEncoding(encoding, infoField.fieldUnit)}
        </p>
      </div>
    </div>
  );
}
