import React, { useEffect } from "react";

import { Box } from "@mui/material";
import { InstantiatePopUpBarListener } from "../Models/HistoricViewlistener";
import { GetServer } from "../Models/ServerCommuncation/Server";
import Screen from "./Screen";

export default function ClientMenuPage({ machineId }: { machineId: string }) {

  const [config, setConfig] = React.useState<Record<string, any> | null>(null);

  useEffect(() => {
    if (config !== null) {
      return;
    }
    async function getConfig() {
      const machineConfig = await GetServer().GetConfigFile();
      InstantiatePopUpBarListener();
      setConfig(machineConfig);
    }
    getConfig();
  }, []);
  if (!config) return null;
  return (
    // <div className=" block scroll h-full min-w-[1200px] overflow-y-hidden">
      <Screen machineConfig={config}></Screen>
    // </div>
  );
}
