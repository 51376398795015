import React, { useEffect } from "react";

import TelemetryLineChart from "./LineChart";
import { useState } from "react";
import {
  Field,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/16/solid";
import { CheckIcon } from "@heroicons/react/20/solid";

const telemetryTypes = [
  { id: 1, key: "PressureIN", name: "Pressure In" },
  { id: 2, key: "PressureOUT", name: "Pressure Out" },
  { id: 3, key: "Temperature_Water", name: "Temperature Water" },
  { id: 4, key: "Temperature_igbt", name: "Temperature Module" },
  { id: 5, key: "Temperature_ctrl_board", name: "Temperature ctrl board" },
  { id: 6, key: "FlowSw", name: "Flow Switch" },
  { id: 7, key: "VibrationX", name: "Vibration in X" },
  { id: 8, key: "VibrationY", name: "Vibration in Y" },
  { id: 9, key: "VibrationZ", name: "Vibration in Z" },
  { id: 10, key: "Inverter_loadPowP", name: "Power" },
  { id: 11, key: "InverterFreqReadout", name: "Inverter Frequency" },
  { id: 12, key: "Temperature_Room", name: "Temperature room" },
];

interface TelemetryType {
  id: number;
  key: string;
  name: string;
}

export function TelemetryChartWrapper(): JSX.Element | null {
  const [selectedType, setSelectedType] = useState<TelemetryType>(
    telemetryTypes[0],
  );

  // Update parent's state when the select menu changes
  const onTypeChange = (type: TelemetryType) => {
    setSelectedType(type);
  };
  return (
      <div>
        <div className="flex justify-center">
          <SelectMenu selected={selectedType} onChange={onTypeChange} />
        </div>

        <div className="p-4 ">
          <TelemetryLineChart
            machineData={{ serialNumber: "14701000006" }}
            telemetryType={selectedType?.key}
            showUnit={true}
          />
        </div>
      </div>
  );
}
interface SelectMenuProps {
  selected: TelemetryType;
  onChange: (newType: TelemetryType) => void;
}

const SelectMenu: React.FC<SelectMenuProps> = ({ selected, onChange }) => {
  return (
    <Field>
      <Label className="block text-sm/6 font-medium text-gray-900">
        Select Telemetry Type
      </Label>
      <Listbox value={selected} onChange={onChange}>
        <div className="relative mt-2">
          <ListboxButton className="grid w-60 cursor-default grid-cols-1 rounded-md bg-white py-1.5 pl-3 pr-2 text-left text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6">
            <span className="col-start-1 row-start-1 truncate pr-6">
              {selected.name}
            </span>
            <ChevronUpDownIcon
              aria-hidden="true"
              className="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4"
            />
          </ListboxButton>

          <ListboxOptions
            transition
            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
          >
            {telemetryTypes.map((telemetryType) => (
              <ListboxOption
                key={telemetryType.id}
                value={telemetryType}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-blue-600 data-[focus]:text-white data-[focus]:outline-none"
              >
                <span className="block truncate font-normal group-data-[selected]:font-semibold">
                  {telemetryType.name}
                </span>

                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600 group-[&:not([data-selected])]:hidden group-data-[focus]:text-white">
                  <CheckIcon aria-hidden="true" className="size-5" />
                </span>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
    </Field>
  );
}
