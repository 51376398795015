import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  RectangleButton as ButtonModel,
  UiElement,
} from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";
import {
  Encoding,
  convertNameToEncoding,
  convertValueToEncoding,
  useEncodingState,
} from "pages/Navigator/Models/DataEncoding";

export default function RectangleButton({
  element,
  ScreenUpdate,
  disabled,
}: {
  element: UiElement;
  ScreenUpdate?: () => void;
  disabled: boolean;
}): JSX.Element {
  return (
    <InnerButton
      button={element as ButtonModel}
      ScreenUpdate={ScreenUpdate}
      disabled={disabled}
    ></InnerButton>
  );
}

function RectangleButtonWrapper({
  bgcolor,
  borderColor,
  children,
}: {
  bgcolor: string;
  borderColor: string;
  children: React.ReactNode[] | React.ReactNode;
}): JSX.Element {
  if (bgcolor === "white") {
    return (
      <div className=" flex h-full flex-grow flex-wrap  content-end justify-center pb-1.5">
        <div
          className={`flex  h-[3em] w-[10em]   content-center justify-center   border-[white] bg-white text-[length:inherit] sm:border md:border-2 lg:border-2 xl:border-2 2xl:border-2`}
        >
          {children}
        </div>
      </div>
    );
  }
  return (
    <div className=" flex h-full flex-grow  flex-wrap content-end justify-center pb-1.5">
      <div
        className={`flex  h-[3em] w-[10em]   content-center justify-center    border-[white] bg-[darkblue] text-[length:inherit] sm:border md:border-2 lg:border-2 xl:border-2 2xl:border-2`}
      >
        {children}
      </div>
    </div>
  );
}

function InnerButton({
  button,
  ScreenUpdate,
  disabled,
}: {
  button: ButtonModel;
  ScreenUpdate?: () => void;
  disabled: boolean;
}) {
  if (button.imageFile) return <ImgButton button={button}></ImgButton>;
  if (button.telemtryField && button.selected)
    return (
      <TelemtryCommandButton
        button={button}
        ScreenUpdate={ScreenUpdate}
        disabled={disabled}
      ></TelemtryCommandButton>
    );
  if (button.telemtryField)
    return <TelemtryButton button={button}></TelemtryButton>;

  if (button.command)
    return (
      <CommandButton
        button={button}
        ScreenUpdate={ScreenUpdate}
        disabled={disabled}
      ></CommandButton>
    );
  //default case

  return (
    <Grid container justifyContent="center">
      <button
        style={{
          height: "8vh",
          width: "20vh",
          color: "white",
          backgroundColor: "darkblue",
          border: "solid ",
          borderRadius: "10%",
        }}
      >
        {button.display_name}{" "}
      </button>
    </Grid>
  );
}

function ImgButton({ button }: { button: ButtonModel }) {
  return (
    <button>
      <img
        src={"shared/uitls/images/" + button!.imageFile}
        alt={button.display_name}
      />
      {button.display_name}
    </button>
  );
}
function TelemtryButton({ button }: { button: ButtonModel }) {
  const Server = GetServer();
  const { encoding } = useEncodingState();
  const LatestData = Server.getLatestData();
  let [val, setVal] = React.useState<number>(
    LatestData.data[button.telemtryField!],
  );
  const unit = convertNameToEncoding(encoding, button.display_name);
  function SetTelemetry(data: DatedIotData) {
    let val = data.data[button.telemtryField!]
      ? convertValueToEncoding(
          encoding,
          data.data[button.telemtryField!],
          button.display_name,
        )
      : convertValueToEncoding(encoding, 0, button.display_name);
    setVal(val);
    setVal(val);
  }
  useEffect(() => {
    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, []);

  return (
    <Grid container justifyContent="center">
      <button
        style={{
          minHeight: "10vh",
          width: "12vh",
          color: "blue",
          backgroundColor: "darkblue",
          border: "solid ",
          borderColor: "darkblue",
          borderRadius: "10%",
        }}
      >
        <p className="flex justify-center text-[1em] text-white">
          {val + " " + unit}
        </p>
      </button>
    </Grid>
  );
}

function CommandButton({
  val,
  button,
  ScreenUpdate,
  disabled,
}: {
  button: ButtonModel;
  val?: number;
  ScreenUpdate?: () => void;
  disabled: boolean;
}) {
  const server = GetServer();
  function sendCommand() {
    let name = button.command!.name;
    server.SendCommand(name, null, () => {});
  }

  return (
    <div
      className="flex aspect-[16/9]  w-[95%] content-center justify-center   	 rounded-[10%] border-4 border-solid border-white bg-[darkblue]"
      style={{ cursor: disabled ? "default" : "pointer" }}
    >
      <button
        onClick={() => {
          if (disabled) {
            return;
          }
          sendCommand();
          ScreenUpdate?.();
        }}
      >
        <p className="flex justify-center text-[1em] text-white">
          {button.display_name}
        </p>
      </button>
    </div>
  );
}

// function TelemtrySelectedButton({button} : {button : ButtonModel})  {
//     <button style={ (val ? {backgroundColor:"white", color: "darkblue"} : {})}
//     > {button.display_name} </button>
// }

function GetSelectedAndVal(
  button: ButtonModel,
  data: DatedIotData,
  encoding: Encoding,
): [boolean, number] {
  let local_val = data.data[button.telemtryField!]
    ? data.data[button.telemtryField!]
    : 0;
  let index = button.selected?.val!;
  let valueforButton = (local_val >> ((2 - index) * 8)) & 0xff;
  let value = convertValueToEncoding(
    encoding,
    valueforButton,
    button.display_name,
  );
  let telemetryField = button.selected!.telemtry_field;
  let expectedVal = button.selected!.val;

  let selected_val = data.data[telemetryField] ? data.data[telemetryField] : 0;
  let selected = selected_val - 1 === expectedVal;
  return [selected, value];
}

function TelemtryCommandButton({
  button,
  ScreenUpdate,
  disabled,
}: {
  button: ButtonModel;
  ScreenUpdate?: () => void;
  disabled: boolean;
}) {
  const Server = GetServer();

  const { encoding } = useEncodingState();

  const unit = convertNameToEncoding(encoding, button.display_name);
  const LatestData = Server.getLatestData();
  let [isselected, value] = GetSelectedAndVal(button, LatestData, encoding);
  let [val, setVal] = React.useState<number>(value);
  let [selected, setSelected] = React.useState<boolean>(isselected);

  function SetTelemetry(data: DatedIotData) {
    let [selected, value] = GetSelectedAndVal(button, data, encoding);

    setVal(value);

    setSelected(selected);
  }
  useEffect(() => {});
  useEffect(() => {
    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, [button]);
  function sendCommand() {
    let name = button.command!.name;
    let arg_val = button.command!.arg_val;

    Server.SendCommand(`${name}`, arg_val!, () => {});
  }
  if (selected) {
    return (
      <RectangleButtonWrapper bgcolor="white" borderColor="white">
        <div
          className="flex  h-full w-full content-center justify-center text-[length:inherit]"
          style={{ cursor: disabled ? "default" : "pointer" }}
        >
          <button disabled={true}>
            <p className=" text-[length:inherit]  text-[darkblue]">
              {val + " " + unit}
            </p>
          </button>
        </div>
      </RectangleButtonWrapper>
    );
  }

  return (
    <RectangleButtonWrapper bgcolor="darkblue" borderColor="white">
      <div
        className=" flex  h-full w-full content-center justify-center text-[length:inherit]"
        style={{ cursor: disabled ? "default" : "pointer" }}
      >
        <button
          onClick={() => {
            sendCommand();
            ScreenUpdate?.();
          }}
        >
          <p className="text-[length:inherit]  text-white ">
            {val + " " + unit}
          </p>
        </button>
      </div>
    </RectangleButtonWrapper>
  );
}
