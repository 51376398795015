/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from "@headlessui/react";
import { HeartIcon, XMarkIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { useOutletContext } from "react-router-dom";
import MA from "shared/utils/images/Machines/MA.png";
import MixStation3D from "shared/utils/images/Machines/MIX3D.png";
import BF32_3D from "shared/utils/images/Machines/BF32_3D.png";
import BF32_LEFT from "shared/utils/images/Machines/BF32_LEFT.png";
import BF32_RIGHT from "shared/utils/images/Machines/BF32_RIGHT.png";

ErrorLogDrawerNavigator.defaultProps = {
  logInfoOpen: false,
  setLogInfoOpen: () => {},
  selectedLog: {},
};

const dateFormat = "HH:mm:ss DD.MM.YYYY";

export default function ErrorLogDrawerNavigator({
  machineData,
  logInfoOpen: open,
  setLogInfoOpen: setOpen,
  selectedLog,
}) {
  let type = "none";
  if (machineData?.type.toLowerCase().includes("mix")) type = "mix";
  if (machineData?.type.toLowerCase().includes("multibooster"))
    type = "multibooster";

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto relative w-96 transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <TransitionChild>
                <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-500 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                  <button
                    type="button"
                    onClick={() => setOpen()}
                    className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                  >
                    <span className="absolute -inset-2.5" />
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                  </button>
                </div>
              </TransitionChild>
              <div className="h-full overflow-y-auto bg-white p-8">
                <div className="space-y-6 pb-16">
                  <div>
                    <div className="aspect-h-16 aspect-w-10 block w-full overflow-hidden rounded-lg">
                      {type === "none" && (
                        <img
                          alt=""
                          src={MA}
                          className="object-contain"
                          style={{ height: "100%" }}
                        />
                      )}
                      {type === "mix" && (
                        <img
                          alt=""
                          src={MixStation3D}
                          className="object-contain"
                          style={{ height: "100%" }}
                        />
                      )}
                      {type === "multibooster" && (
                        <img
                          alt=""
                          src={BF32_3D}
                          className="object-contain"
                          style={{ height: "100%" }}
                        />
                      )}
                    </div>
                    <div className="mt-4 flex items-start justify-between">
                      <div>
                        <h2 className="text-base font-semibold leading-6 text-gray-900">
                          <span className="sr-only">Details for </span>
                          {selectedLog?.name || "Untitled"}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900">Information</h3>
                    <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">Pump number</dt>
                        <dd className="text-gray-900">
                          {selectedLog?.SlaveId || "No pump number"}
                        </dd>
                      </div>
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">Serial number</dt>
                        <dd className="text-gray-900">
                          {machineData?.serialNumber || "No serial number"}
                        </dd>
                      </div>
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">Date</dt>
                        <dd className="text-gray-900">
                          {moment(selectedLog.date, dateFormat).format(
                            "MMMM Do YYYY",
                          )}
                        </dd>
                      </div>
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">Time</dt>
                        <dd className="text-gray-900">
                          {moment(selectedLog.date, dateFormat).format(
                            "HH:mm:ss",
                          )}
                        </dd>
                      </div>
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">Code name</dt>
                        <dd className="text-gray-900">
                          {selectedLog?.codeName || "No code name"}
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900">Description</h3>
                    <div className="mt-2 flex items-center justify-between">
                      <p className="text-sm italic text-gray-500">
                        {selectedLog?.description || "No description provided"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
