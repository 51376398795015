import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  BigInfofield as BigInfoFieldModel,
  UiElement,
} from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";
import {
  convertNameToEncoding,
  convertValueToEncoding,
  useEncodingState,
} from "pages/Navigator/Models/DataEncoding";

function BigRectangleButtonWrapper({
  bgcolor,
  borderColor,
  children,
}: {
  bgcolor: string;
  borderColor: string;
  children: React.ReactNode[] | React.ReactNode;
}): JSX.Element {
  if (bgcolor === "white") {
    return (
      <div className=" flex h-full flex-grow flex-col flex-wrap  content-end justify-center pb-1.5">
        <div
          className={`flex  h-[4em] w-[10em] flex-col   content-center justify-center   border-[white] bg-white text-[length:inherit] sm:border md:border-2 lg:border-2 xl:border-2 2xl:border-2`}
        >
          {children}
        </div>
      </div>
    );
  }
  return (
    <div className=" flex h-full flex-grow flex-col  flex-wrap content-end justify-center pb-1.5">
      <div
        className={`flex  h-[4em] w-[10em]   content-center justify-center    border-[white] bg-[darkblue] text-[length:inherit] sm:border md:border-2 lg:border-2 xl:border-2 2xl:border-2`}
      >
        {children}
      </div>
    </div>
  );
}
export default function BigButton({
  element,
  ScreenUpdate,
  disabled,
}: {
  element: UiElement;
  ScreenUpdate?: () => void;
  disabled: boolean;
}) {
  return (
    <BigButtonInner button={element as BigInfoFieldModel}></BigButtonInner>
  );
}

function BigButtonInner({ button }: { button: BigInfoFieldModel }) {
  const Server = GetServer();
  const LatestData = Server.getLatestData();
  let [val, setVal] = React.useState<number>(
    LatestData.data[button.telemtryField!],
  );
  const { encoding } = useEncodingState();
  useEffect(() => {
    function SetTelemetry(data: DatedIotData) {
      if (button.fieldUnit !== undefined) {
        setVal(
          convertValueToEncoding(
            encoding,
            data.data[button.telemtryField],
            button.fieldUnit,
          ),
        );
        return;
      }
      setVal(data.data[button.telemtryField]);
    }
    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, [Server, button, encoding]);
  return (
    <BigRectangleButtonWrapper borderColor="white" bgcolor="white">
      <div className="length:text- col-span-2 flex h-full w-full flex-wrap content-end justify-center">
        <div className="fle justify-center text-[length:inherit]">
          <p className="text-[4em] font-semibold text-white">{val}</p>
        </div>
        <div className="flex justify-center text-[length:inherit]">
          <p className="text-[4em] font-semibold text-white">
            {convertNameToEncoding(encoding, button.fieldUnit)}
          </p>
        </div>
      </div>
      <div className="flex h-full w-full flex-wrap content-end justify-center">
        <p className="text-[4em] font-semibold text-white">
          {button.display_name}
        </p>
      </div>
    </BigRectangleButtonWrapper>
  );
}
