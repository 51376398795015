import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Grid, IconButton, ListItem, Paper, Switch, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { ConvertEepromData, componentNames } from "./DashBoardParsing";

import { convertNameToEncoding, convertValueToEncoding, useEncodingState } from "pages/Navigator/Models/DataEncoding";
import { useWindowDimensions } from "pages/Navigator/Models/dashBoardUtilities";

import { TableBody, TableCellHeader, TableContainer, TableRow, Table, TableHeader, TableCellBody } from "./DashBoardTable/DashBoardTable";
import { ButtonChooserSettings, DefaultSettings, Settings, SliderSettings } from "pages/Navigator/Models/ServerCommuncation/Settings";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { ProgressCircle } from "../utils";
type MachineResponseType = "OK" | "Error";

type componentView = ({ setting, settingAttributes, defaultSetting, CurrentSettings, value, SetNewValue }: { setting: [string, string]; settingAttributes: Settings; defaultSetting: [string, string]; CurrentSettings: [string, string]; value: string | null; SetNewValue: React.Dispatch<React.SetStateAction<string | null>> }) => JSX.Element;
const SettingValueViews: Record<componentNames, componentView | null> = {
  "na": null,
  toggle: FlagButton,
  buttonChooserX: ButtonChooserSetting,
  buttonChooser: ButtonChooserSetting,
  slider: ValueAdjusterSettingsView,
};


function sortEepromData(data: Record<string, string>, keyvalueOrder: string[]): Map<string, string> {
  console.log(keyvalueOrder);
  return keyvalueOrder.reduce((obj: Map<string, string>, key: string) => {


    obj.set(key, data[key]);
    return obj;
  }, new Map());



}

export default function SettingsView() {
  const server = GetServer();

  const [Settings, SetSettings] = React.useState<Map<string, string>>(new Map());
  const [CurrentSettings, SetCurrentSettings] = React.useState<Map<string, string>>(new Map());
  const [DefualtSettings, SetDefaultSetings] = React.useState<Map<string, string>>(new Map());
  const [SettingsAttributesDict, SetSettingsAttributesDict] = React.useState<null | Record<string, Settings>>(null);

  const [ChangeStatus, SetChangeStatus] = React.useState<boolean>(false);
  const [MachineResponse, SetMachineResponse] = React.useState<MachineResponseType | null>(null);
  const [Err, SetErr] = React.useState(false);
  const [Sending, SetSending] = React.useState<{ sending: boolean, status: string }>({ sending: false, status: "" });
  // TODO: fix this, it is really suboptimal


  useEffect(() => {
    if (CurrentSettings.size !== 0 && DefualtSettings.size !== 0 && Settings.size !== 0) {
      return;
    }


    server.GetEepromSettings((mabye_data, err) => {

      if (!err) {
        if (Err) {
          SetErr(false);
        }
        const data = mabye_data![1];


        let settings = sortEepromData(data, mabye_data![0]);


        server.GetMachineDefaultSettingsMemJSON((mabye_data, err) => {
          if (!err) {
            SetDefaultSetings(new Map(Object.entries(mabye_data!)));

            SetSettings(new Map(settings));
            SetCurrentSettings(new Map(settings));
            return;
          }
          SetErr(true);
        })
        server.GetMachineAttributes((mabye_data, err) => {
          if (!err) {
            SetSettingsAttributesDict(mabye_data!);

            return;
          }
          SetErr(true);
        })


        return;
      }

      SetErr(true);

    });
  }, [server, DefualtSettings, CurrentSettings, Settings, Err]);









  if (Err) {
    return <Grid item xs container justifyContent={"center"} direction={"column"} >
      <Typography fontSize={"0.6vmax"} color={"black"} variant="button">Couldnt fetch eeprom settings.</Typography>
    </Grid>
  }

  if (Sending.sending) {
    return (<Grid item xs container justifyContent={"center"} direction={"column"} >

      <Grid item xs><Grid item xs container justifyContent={"center"}><Typography fontSize={"0.6vmax"} color={"black"} variant="button">{Sending.status}</Typography></Grid></Grid>
      <ProgressCircle/>

    </Grid >);
  }

  if (MachineResponse !== null) {
    return (

      <Grid item xs container direction={"column"} justifyContent={"center"} justifyItems={"center"} height={"100%"}>
        <ListItem><MachineResponseView MachineResponse={MachineResponse} SetMachineResponse={SetMachineResponse}></MachineResponseView></ListItem>
      </Grid>
    );
  }
  if (SettingsAttributesDict == null || Settings.size === 0) {
    return <Grid container xs={12} justifyContent={"center"} alignContent={"center"} borderRadius={"0.5vw"} >
      <ProgressCircle />
    </Grid>;
  }

  if (ChangeStatus) {

    return (
      <>

        <TableContainer className=" w-" >
          <Table >
            <TableHeader>
              <TableRow >
                <TableCellHeader colspan={6}>
                  <div className="flex justify-center"><Button
                    fullWidth={true}
                    onClick={() => {
                      server.SetEepromSettings(Object.fromEntries(Settings), (ok, err) => {
                        SetSending({ sending: true, status: "fetching new settings from machine" });

                        server.GetEepromSettings((mabye_data, err) => {

                          if (!err) {
                            const data = mabye_data![1];

                            let settings = sortEepromData(data, mabye_data![0]);
                            SetSettings(new Map(settings));
                            SetCurrentSettings(new Map(settings));
                            if (ok) {


                              SetMachineResponse("OK");
                              SetSending({ sending: false, status: "" });
                              return;


                            }

                            SetMachineResponse("Error");
                            SetSending({ sending: false, status: "" });

                          }
                        });


                      });
                      SetSending({ sending: true, status: "setting new settings on machine" });


                      SetChangeStatus(false);


                    }}
                  >
                    <Typography fontSize={"0.6vmax"} variant="button" >Send Settings to  machine </Typography>
                  </Button>
                  </div>
                </TableCellHeader>
              </TableRow>
              <TableInfoHeader></TableInfoHeader>
            </TableHeader>

            <TableBody>

              <EepromSettingsView Settings={Settings} changeStatus={ChangeStatus} SettingsAttributesDict={SettingsAttributesDict} DefaultSettings={DefualtSettings} CurrentSettings={CurrentSettings} SetSettings={SetSettings} SetChangeStatus={SetChangeStatus}></EepromSettingsView>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
  return (


    <TableContainer>
      <Table >

        <TableHeader>
          <TableInfoHeader></TableInfoHeader>
        </TableHeader>
        <TableBody>
          <EepromSettingsView Settings={Settings} changeStatus={ChangeStatus} SettingsAttributesDict={SettingsAttributesDict} DefaultSettings={DefualtSettings} CurrentSettings={CurrentSettings} SetSettings={SetSettings} SetChangeStatus={SetChangeStatus}></EepromSettingsView>
        </TableBody>
      </Table>
    </TableContainer>

  );
}


function TableInfoHeader(): JSX.Element {
  return (
    <TableRow >
      <TableCellHeader >
        <p className="flex font-semibold text-black  text-[0.7em] justify-center items-center tracking-wide" >
          Name
        </p>

      </TableCellHeader>

      <TableCellHeader>
        <div className="flex basis-10/12 justify-center">

          <p className="flex font-semibold text-black  text-[0.7em]  tracking-wide" >

            Factory
          </p>
        </div>
      </TableCellHeader >

      <TableCellHeader >
        <div className="flex justify-center">

          <p className="flex font-semibold text-black  text-[0.7em] justify-center items-center tracking-wide" >
            Current
          </p>
        </div>

      </TableCellHeader>
      <TableCellHeader >
        <div className="flex justify-center">
          <p className="flex font-semibold text-black text-[0.7em] justify-center text-center items-center tracking-wide" >
            New
          </p>
        </div>
      </TableCellHeader>
      <TableCellHeader >
        <div className="flex justify-center">

          <p className="flex font-semibold text-black   text-[0.7em] justify-center content-center text-center items-center tracking-wide" >
            unit
          </p>
        </div>
      </TableCellHeader>
      <TableCellHeader>
        <div className="flex justify-center">

          <p className="flex font-semibold  text-black  text-[0.7em] justify-center items-center tracking-wide" >

            change
          </p>
        </div>
      </TableCellHeader>
    </TableRow >
  )
}

function MachineResponseView({ MachineResponse, SetMachineResponse }: { MachineResponse: MachineResponseType; SetMachineResponse: React.Dispatch<React.SetStateAction<MachineResponseType | null>> }): JSX.Element {
  const server = GetServer();

  switch (MachineResponse) {
    case "Error":
      return (
        <Grid item xs direction={"column"}>

          <Grid item xs={12} justifyContent={"center"} border={1} borderColor={"darkblue"} borderRadius={"0.5vw"}>
            <Typography fontSize={"0.6vmax"} variant="button" color={"black"}>Machine couldnt update status</Typography>
          </Grid>
          <Grid item xs={12} justifyContent={"center"} border={1} borderColor={"darkblue"} borderRadius={"0.5vw"}>
            <Button onClick={() => {
              SetMachineResponse(null);
            }}>
              <Typography fontSize={"0.6vmax"} variant="button" color={"black"} >Go back to navigator mode.</Typography>
            </Button>
          </Grid>
        </Grid>

      );
    case "OK": {
      return (
        <Grid item container sx={{ flex: "1", minHeight: 0, }} direction={"column"} rowGap={3} >
          <Grid item container xs={1} justifyContent={"center"} alignContent={"center"} borderRadius={"0.5vw"}>
            <Grid item xs={6}><Typography fontSize={"0.6vmax"} variant="button" color={"black"} >Machine Updated succesfully</Typography></Grid>
          </Grid>
          <Grid item xs={2} container direction={"column"} justifyContent={"center"} alignContent={"center"} bgcolor={"white"} borderRadius={"0.5vw"}>
            <Button sx={{ height: "100%" }} fullWidth={true} onClick={() => {
              server.SendCommand("RebootSystem", null, () => { });
              server.disconnectNoError();
            }}>
              <Grid item container xs={12} direction={"column"} >
                <Grid item xs><Typography fontSize={"0.6vmax"} variant="button" color={"black"}  >Reboot the machine to update settings</Typography></Grid>
                <Grid item xs><Typography fontSize={"0.6vmax"} variant="button" color={"black"}  > Machine will leave navigator mode.</Typography></Grid>

              </Grid>
            </Button>
          </Grid>
          <Grid item xs={2} justifyContent={"center"} alignContent={"center"} bgcolor={"white"} borderRadius={"0.5vw"}>
            <Button sx={{ height: "100%" }} fullWidth={true} onClick={() => {
              SetMachineResponse(null);
            }}>
              <Grid container xs direction={"column"} >

                <Grid item xs><Typography fontSize={"0.6vmax"} variant="button" color={"black"}  >Go Back To Navigator Mode</Typography></Grid>
                <Grid item xs ><Typography fontSize={"0.6vmax"} variant="button" color={"black"}  > Machine will need to be rebooted to update all settings.</Typography></Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      );
    }
  }
}

function EepromSettingsView({
  Settings,
  CurrentSettings,
  SettingsAttributesDict,
  SetSettings,
  DefaultSettings,
  changeStatus,

  SetChangeStatus,
}: {
  Settings: Map<string, string>;
  CurrentSettings: Map<string, string>;
  SettingsAttributesDict: Record<string, Settings>;
  DefaultSettings: Map<string, string>;
  changeStatus: boolean;
  SetSettings: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  SetChangeStatus: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <>
      {Array.from(Settings.keys())

        .map((key) => {
          let setting = Settings.get(key)!;
          let currSetting = CurrentSettings.get(key)!;
          let defaultSetting = DefaultSettings.get(key)!;
          let settingAttributes = SettingsAttributesDict[key]!;
          console.log(key)
          console.log(SettingsAttributesDict)

          return (
            <SettingValueView setting={[key, setting]} DefaultSetting={[key, defaultSetting]} settingAttributes={settingAttributes} CurrentSettings={[key, currSetting]} SetSettings={SetSettings} SetChangeStatus={SetChangeStatus}></SettingValueView>
          );
        })}
    </>
  );
}



function SettingValueView({
  setting,
  SetSettings,
  settingAttributes,
  CurrentSettings,
  DefaultSetting,
  SetChangeStatus,
}: {
  setting: [string, string];
  settingAttributes: Settings;
  CurrentSettings: [string, string];
  DefaultSetting: [string, string];
  SetSettings: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  SetChangeStatus: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  let [, SetValue] = React.useState<string | null>(null);
  let [newValue, SetNewValue] = React.useState<string | null>(null);
  let [name, ePromVal] = setting;

  let uiElementType = settingAttributes.uielement;
  const Controller = SettingValueViews[uiElementType];



  useEffect(() => {
    SetValue(ePromVal);
  }, [setting, ePromVal]);
  if (Controller == null) {
    return null;
  }

  if (newValue == null) {
    return (<TableRow>
      <Controller setting={setting} settingAttributes={settingAttributes} defaultSetting={DefaultSetting} CurrentSettings={CurrentSettings} SetNewValue={SetNewValue} value={newValue}></Controller>
    </TableRow>);
  }
  return (<>
    <TableRow>
      <Controller setting={setting} settingAttributes={settingAttributes} defaultSetting={DefaultSetting} CurrentSettings={CurrentSettings} SetNewValue={SetNewValue} value={newValue}></Controller>
    </TableRow>
    <TableRow>
      <PresOkButton name={name} value={newValue} SetSettings={SetSettings} SetChangeStatus={SetChangeStatus} SetNewValue={SetNewValue}></PresOkButton>

    </TableRow>
  </>);

}

function ValueSettingView({ value, SetNewValue }: { setting: [string, string]; defaultSetting: [string, string]; CurrentSettings: [string, string]; value: string | null; SetNewValue: React.Dispatch<React.SetStateAction<string | null>> }) {
  return (
    <>
      <Grid item container xs={4} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
        <Typography fontSize={"0.6vmax"} variant={"button"} color={"black"}>
          {value?.toString()}
        </Typography>
      </Grid>
      <Grid item container xs={5} justifyContent={"center"} alignContent={"center"}>
        <Grid item xs={9} bgcolor={"white"}>
          <TextField
            inputProps={{ style: { textAlign: "center" } }}
            size={"small"}
            defaultValue={value}
            onChange={(event) => {
              let new_value = event.target.value;

              SetNewValue(new_value);
            }}
          ></TextField>
        </Grid>
      </Grid>
    </>
  );
}
function TailWindSwitch({ checked, disabled, onChange }: { checked: boolean, disabled?: boolean, onChange?: React.ChangeEventHandler<HTMLInputElement> }): JSX.Element {
  return (<label className="inline-flex items-center cursor-pointer">
    <input type="checkbox" value="" className="sr-only peer" checked={checked} disabled={disabled ? disabled : false} onChange={onChange} />
    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
  </label>)
}
function FlagButton({ setting, settingAttributes, CurrentSettings, defaultSetting, value, SetNewValue }: { setting: [string, string]; settingAttributes: Settings; defaultSetting: [string, string]; CurrentSettings: [string, string]; value: string | null; SetNewValue: React.Dispatch<React.SetStateAction<string | null>> }) {
  const value_int = parseInt(value!);
  const [name, ePromVal] = setting;
  const [, current_val] = CurrentSettings;
  const [, default_val] = defaultSetting;
  const displayName = settingAttributes.display_name;


  const checked = value_int === 0 ? false : !isNaN(value_int) ? true : parseInt(ePromVal) === 0 ? false : true;

  const checked_currently = parseInt(current_val) === 0 ? false : true;
  const check_default = parseInt(default_val) === 0 ? false : true;
  return (
    <>
      <TableCellBody className="break-all" >
        <p className="text-black text-[0.6em] text-wrap text-break tracking-wide	 break-words font-semibold justify-start ">
          {displayName}</p>
      </TableCellBody>
      <TableCellBody  >
        <div className="flex justify-center">
          <TailWindSwitch checked={check_default} disabled={true}></TailWindSwitch>

        </div>
      </TableCellBody>
      <TableCellBody >
      </TableCellBody>
      <TableCellBody >
        <div className="flex justify-center">

          <TailWindSwitch checked={checked_currently} disabled={true}></TailWindSwitch>
        </div>

      </TableCellBody>
      <TableCellBody>
      </TableCellBody>

      <TableCellBody >
        <div className="flex justify-center">

          <TailWindSwitch
            checked={checked}
            onChange={(event) => {
              SetNewValue((prev) => {
                return prev === "0" ? "1" : "0";
              });
            }}
          ></TailWindSwitch>
        </div>

      </TableCellBody>
    </>
  );
}

function DropDownMenu({ settingAttributes, ePromVal, disable, SetNewValue }: { settingAttributes: ButtonChooserSettings, ePromVal: number, disable: boolean, SetNewValue: React.Dispatch<React.SetStateAction<string | null>> }): JSX.Element {

  const ChosenOption = settingAttributes.options[ePromVal]!
  console.log(ChosenOption);
  return (
    <Menu as="div" className=" flex" >
      <div>
        <MenuButton disabled={disable} className="inline-flex justify-center  rounded-md bg-white   font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {ChosenOption}
          <ChevronDownIcon aria-hidden="true" className=" h-0.5 w-0.5  text-gray-400" />
        </MenuButton>
      </div>

      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        {
          settingAttributes.options.map((option, idx) => {
            return (<MenuItem
              as="button"
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
              onClick={() => SetNewValue(idx.toString())}
            > q
            </MenuItem>)
          })
        }

      </MenuItems>
    </Menu>
  )
}
function ButtonChooserSetting({ setting, CurrentSettings, settingAttributes, defaultSetting, value, SetNewValue }: { setting: [string, string]; settingAttributes: Settings; defaultSetting: [string, string]; CurrentSettings: [string, string]; value: string | null; SetNewValue: React.Dispatch<React.SetStateAction<string | null>> }) {
  const value_int = parseInt(value!);
  const [name, ePromVal] = setting;
  const [, current_val] = CurrentSettings;
  const [, default_val] = defaultSetting;
  const displayName = settingAttributes.display_name;
  const ChangeOption = !isNaN(value_int) ? value_int : parseInt(ePromVal);

  return (
    <>
      <TableCellBody className="break-all" >
        <p className="text-black text-[0.6em] text-wrap text-break tracking-wide	 break-words font-semibold justify-start ">
          {displayName}</p>
      </TableCellBody>
      <TableCellBody  >
        <div className="flex justify-center">
          <DropDownMenu settingAttributes={settingAttributes as ButtonChooserSettings} SetNewValue={SetNewValue} ePromVal={parseInt(default_val)} disable></DropDownMenu>

        </div>
      </TableCellBody>
      <TableCellBody >
      </TableCellBody>
      <TableCellBody >
        <div className="flex justify-center">

          <DropDownMenu settingAttributes={settingAttributes as ButtonChooserSettings} SetNewValue={SetNewValue} ePromVal={parseInt(current_val)} disable></DropDownMenu>
        </div>

      </TableCellBody>
      <TableCellBody>
      </TableCellBody>

      <TableCellBody >
        <div className="flex justify-center">
          <DropDownMenu settingAttributes={settingAttributes as ButtonChooserSettings} SetNewValue={SetNewValue} ePromVal={ChangeOption} disable={false}></DropDownMenu>

        </div>

      </TableCellBody>
    </>
  );
}



function ValueAdjusterSettingsView({ setting, settingAttributes, CurrentSettings, defaultSetting, value, SetNewValue }: { setting: [string, string]; settingAttributes: Settings; defaultSetting: [string, string]; CurrentSettings: [string, string]; value: string | null; SetNewValue: React.Dispatch<React.SetStateAction<string | null>> }) {
  settingAttributes = settingAttributes as DefaultSettings & SliderSettings;
  const [name, ePromVal] = setting;
  const Interval = { interval: parseInt(settingAttributes.interval), low: parseInt(settingAttributes.low), high: parseInt(settingAttributes.high) };
  const adjusted_val = ConvertEepromData(name, parseInt(value!));
  const [, current_val] = CurrentSettings;

  const { encoding } = useEncodingState();
  const [, default_val] = defaultSetting;
  const adjuststed_default_val = convertValueToEncoding(encoding, ConvertEepromData(name, parseInt(default_val!)), settingAttributes.unit_name);
  const adjusted_current_val = convertValueToEncoding(encoding, ConvertEepromData(name, parseInt(current_val!)), settingAttributes.unit_name);


  const adjusted_changed_val_local = isNaN(adjusted_val) ? ConvertEepromData(name, parseInt(ePromVal!)) : adjusted_val;


  if (!Interval) return <></>;

  return (
    <>
      <TableCellBody className=" break-all max-w-[2vw] "  >
        <p className="text-black text-[0.7em]  tracking-wide	 break-words font-semibold justify-start ">
          {settingAttributes.display_name}
        </p>
      </TableCellBody>
      <TableCellBody style={{ maxWidth: "2vw" }}>

        <p className="text-black text-[0.7em] tracking-wide	  font-semibold justify-center flex">
          {adjuststed_default_val.toString()}
        </p>

      </TableCellBody>
      <TableCellBody style={{ maxWidth: "2vw" }}>
        <div className="flex items-center justify-center">
          <p className="text-black text-[0.7em] tracking-wide	  font-semibold justify-center flex">
            {adjusted_current_val.toString()}
          </p>
        </div>

      </TableCellBody>
      <TableCellBody style={{ maxWidth: "2vw" }}>
        <div className="flex basis-10/12 justify-center items-center" >

          <p className="text-black text-[0.7em] tracking-wide	  font-semibold justify-center flex">
            {convertValueToEncoding(encoding, adjusted_changed_val_local, settingAttributes.unit_name)}
          </p>
        </div>

      </TableCellBody>

      <TableCellBody style={{ maxWidth: "2vw" }} >
        <div className="flex justify-center items-center basis-10/12">
          <CreateUnitTextElement unit={settingAttributes.unit_name} ></CreateUnitTextElement>
        </div>
      </TableCellBody>
      <TableCellBody style={{ maxWidth: "5vw" }}>
        <div className="flex justify-center content-center items-center">
          <div className="flex justify-center content-center items-center basis-4/12" >
            <IconButton
              disabled={value ? parseInt(value) <= Interval!.low : parseInt(ePromVal) <= Interval!.low}
              onClick={() => {
                SetNewValue((prev) => {
                  if (prev != null) {
                    let prev_int = parseInt(prev);
                    return (prev_int - Interval.interval).toString();
                  }
                  return (parseInt(ePromVal) - Interval.interval).toString();
                });
              }}
            >

              <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
            </IconButton>
          </div>
          <div className="flex basis-4/12 justify-center pl-[0.5vmax]">
            <IconButton
              disabled={value ? parseInt(value) >= Interval!.high : parseInt(ePromVal) >= Interval!.high}
              onClick={() => {
                SetNewValue((prev) => {
                  if (prev != null) {
                    let prev_int = parseInt(prev);
                    return (prev_int + Interval.interval).toString();
                  }

                  return (parseInt(ePromVal) + Interval.interval).toString();
                });
              }}
            >
              <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
            </IconButton>
          </div>
        </div>
      </TableCellBody>
    </>
  );
}

function CreateUnitTextElement({ unit }: { unit: string }): JSX.Element {
  if (unit.includes("/")) {
    let [first, second] = unit.split("/");

    return <>
      <p className="text-black text-[0.7em] tracking-wide	  font-semibold justify-center flex">
        {first + "/"}</p>
      <br />
      <p className="text-black text-[0.7em] tracking-wide	  font-semibold justify-center flex">
        {second}
      </p>

    </>
  }
  return <p className="text-black text-[0.7em] tracking-wide	  font-semibold justify-center flex">
    {unit}
  </p>

}

function PresOkButton({
  name,
  value,
  SetSettings,
  SetChangeStatus,
  SetNewValue,
}: {
  name: string;
  value: string | null;
  SetSettings: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  SetChangeStatus: React.Dispatch<React.SetStateAction<boolean>>;
  SetNewValue: React.Dispatch<React.SetStateAction<string | null>>;
}): JSX.Element | null {
  if (!value) {
    return null;
  }
  return (
    <TableCellBody colspan={6}>
      <div className="flex bg-white justify-center content-center items-center rounded-[0.5vmax] ">

        <p className=" text-[0.7em] font-semibold tracking-wide cursor-pointer text-black"
          onClick={() => {
            SetSettings((prev) => {
              return new Map(prev.set(name, value));
            });
            SetNewValue(null);
            SetChangeStatus(true);

          }}>Ok</p>
      </div>
    </TableCellBody>
  );
}
