import * as React from "react";

export function TableContainer({
    children,
    className,
    displayOverflow,
}: {
    children?: React.ReactNode[] | React.ReactNode;
    displayOverflow?: boolean;
    className?: string;

}): JSX.Element {

    if (displayOverflow) {
        return <div className={` relative text-[length:inherit] max-w-full overflow-visible  min-w-full min-h-0 h-full bg-white  shadow  flex flex-col flex-grow rounded-lg ${className}`}>
            {children}
        </div>
    }
    return (
        <div
            className={` relative text-[length:inherit] max-w-full overflow-x-hidden min-w-full min-h-0 h-full bg-white scroll shadow  flex flex-col flex-grow rounded-lg ${className}`}
        >
            {children}
        </div>
    );
}

export function Table({
    children,
    className

}: {
    children?: React.ReactNode[] | React.ReactNode;
    className?: string
}): JSX.Element {
    return (
        <table className={` relative text-[length:inherit] min-w-full table bg-white  ${className}  `}>
            {children}
        </table>
    );
}

export function TableHeader({
    children,
    className
}: {
    children?: React.ReactNode[] | React.ReactNode;
    className?: string

}): JSX.Element {
    return (
        <thead className={`  text-[length:inherit] bg-white h-full sticky top-0 z-1 ${className}`}>
            {children}
        </thead>
    );
}

export function TableBody({
    children,
    style,
    className
}: {
    children?: React.ReactNode[] | React.ReactNode;
    className?: string

    style?: React.CSSProperties;
}): JSX.Element {
    return (
        <tbody style={style} className={` relative text-[length:inherit] divide-y divide-gray-200 bg-white ${className}`}>
            {children}
        </tbody>
    );
}

export function TableRow({
    children,
    className
}: {
    children?: React.ReactNode[] | React.ReactNode;
    className?: string;
}): JSX.Element {
    return (
        <tr className={` relative text-[length:inherit] ${className}`}> {/* Fixed height for each row */}
            {children}
        </tr>
    );
}

export function TableCellHeader({
    children,
    sticky = true,
    colspan,
    className
}: {
    children?: React.ReactNode[] | React.ReactNode;
    sticky?: boolean;
    colspan?: number;
    className?: string;
}): JSX.Element {
    if (sticky) {
        return (
            <th colSpan={colspan} className={`  text-[length:inherit] uppercase bg-white sticky top-0 pl-[0.5em] ${className}`}>
                {children}
            </th>
        );
    }

    return (
        <th className={` relative text-[length:inherit]  uppercase bg-white ${className}`}>
            {children}
        </th>
    );
}

export function TableCellBody({
    children,
    colspan,
    style,
    className
}: {
    children?: React.ReactNode[] | React.ReactNode;
    colspan?: number;
    className?: string;
    style?: React.CSSProperties;
}): JSX.Element {
    return (
        <td colSpan={colspan} style={style} className={`  relative text-[length:inherit] uppercase h-16 pl-[0.5em]  ${className} `}>
            {children}
        </td>
    );
}
