import React, { useState } from "react";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { useMachineBySNQuery, useMachineErrorLogQuery } from "hooks/machine";
import ErrorLogListSkeleton from "pages/Machine/components/ErrorLogListSkeleton";
import {
  decryptErrorCodes as importedDecryptErrorCodes,
  decryptWarningCodes as importedDecryptWarningCodes,
} from "shared/utils/decriptErrors";
import ErrorLogListNavigator from "pages/Machine/components/ErrorLogListNavigator";

export default function ErrorLogView() {
  const server = GetServer();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const serialNumber = server.getmahineId();
  // const { machineData } = useOutletContext();
  const { data, isLoading, error } = useMachineErrorLogQuery(serialNumber);

  const {
    data: machineData,
    isLoading: machineLoading,
    error: machineError,
  } = useMachineBySNQuery(serialNumber);

  var logList: any[] = [];
  if (data?.data !== undefined) {
    logList = convertLogList(data?.data);
  }

  return (
    <ol className=" divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
      {isLoading ? (
        <ErrorLogListSkeleton />
      ) : logList.length === 0 ? (
        <p className=" py-2 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-3">
          No errors found
        </p>
      ) : (
        <ErrorLogListNavigator
          machineData={machineData?.data?.machine[0]}
          logList={logList}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </ol>
  );
}

//TODO fix error log using the json command in the backend, it cannot convert properly the json from michael
interface LogEntry {
  date: string;
  ErrorCode1: number;
  ErrorCode2: number;
  ErrorCode3: number;
  GUIError1: number;
  GUIError2: number;
  WarningCode1: number;
  WarningCode2: number;
  GUIWarning1: number;
  SlaveId?: string;
}

interface DecryptedEntry {
  SlaveId: string;
  date: string;
  [key: string]: any;
}

const convertLogList = (logList: LogEntry[]): DecryptedEntry[] => {
  console.log("logList", logList);
  let errors: DecryptedEntry[] = [];
  let warnings: DecryptedEntry[] = [];

  for (let i = 0; i < logList.length; i++) {
    const dates = logList[i].date;
    const ErrorCode1 = logList[i].ErrorCode1;
    const ErrorCode2 = logList[i].ErrorCode2;
    const ErrorCode3 = logList[i].ErrorCode3;
    const GUIError1 = logList[i].GUIError1;
    const GUIError2 = logList[i].GUIError2;
    const WarningCode1 = logList[i].WarningCode1;
    const WarningCode2 = logList[i].WarningCode2;
    const GUIWarning1 = logList[i].GUIWarning1;
    const SlaveId = logList[i]?.SlaveId || "0";

    const errorsList = importedDecryptErrorCodes(
      ErrorCode1,
      ErrorCode2,
      ErrorCode3,
      GUIError1,
      GUIError2,
    );

    errorsList.forEach((error) => {
      error.SlaveId = SlaveId;
      error.date = dates;
    });

    const warningsList = importedDecryptWarningCodes(
      WarningCode1,
      WarningCode2,
      GUIWarning1,
    ).map((warning) => ({
      ...warning,
      SlaveId,
      date: dates,
    }));

    errors = errors.concat(errorsList);
    warnings = warnings.concat(warningsList);
  }

  let combinedList: DecryptedEntry[] = errors.concat(warnings);

  // Sort by date
  combinedList.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  return combinedList;
};

// Assuming these functions are defined elsewhere
declare function decryptErrorCodes(
  ErrorCode1: number,
  ErrorCode2: number,
  ErrorCode3: number,
  GUIError1: number,
  GUIError2: number,
): DecryptedEntry[];

declare function decryptWarningCodes(
  WarningCode1: number,
  WarningCode2: number,
  GUIWarning1: number,
): DecryptedEntry[];
