import React, { useEffect, useState } from "react";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { InfoElement } from "./InfoElement";
import { ProgressCircle } from "../utils";

type InverterData = {
  "Total Run Hours": number;
  "Total On Hours": number;
  "Total kWh": number;
};

type TotalTripCountersData = {
  "Install Time": string;
  "Last Reset Trip Time": string & { [key: string]: InverterData };
};

export default function DisplayInverters(): JSX.Element {
  const [inverters, setInverters] = useState<Record<
    string,
    InverterData
  > | null>(null);
  const [selectedInverter, setSelectedInverter] = useState<string>("Inverter1");
  const server = GetServer();

  useEffect(() => {
    server.SendCommand("GetTotalCountersJSON", null, (data: any, err: any) => {
      if (!err) {
        setInverters(parseInverters(data));
      }
    });
  }, [server]);

  function parseInverters(
    TotalTripCounters: TotalTripCountersData,
  ): Record<string, InverterData> {
    const {
      "Install Time": _,
      "Last Reset Trip Time": __,
      ...rest
    } = TotalTripCounters;
    return rest;
  }

  if (!inverters) {
    return <ProgressCircle/>;
  }

  return (
    <div className="">
      {/* <div className="mx-2 mt-4">
        <select
          value={selectedInverter}
          onChange={(e) => setSelectedInverter(e.target.value)}
          className="w-full rounded-md border border-gray-300 p-2"
        >
          {Object.keys(inverters).map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </select>
      </div> */}

      <div className="">
        <div className="px-4 py-6 sm:px-6">
        <h3 className="text-base font-semibold leading-7 text-gray-900 flex flex-col md:flex-row md:items-center md:gap-2">
          Trip Counter:
          <div className="flex-1">
            <select
              value={selectedInverter}
              onChange={(e) => setSelectedInverter(e.target.value)}
              className="w-full md:w-full rounded-md border border-gray-300 p-1"
            >
              {Object.keys(inverters).map((key) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </select>
          </div>
        </h3>
        </div>
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            {Object.entries(inverters[selectedInverter]).map(([key, value]) => (
              <InfoElement key={key} name={key} value={value.toString()} />
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
