// implement the go back button

import React from "react";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
//@ts-ignore
import goBackImg from "shared/utils/images/GoBackButton.png";

export default function GoBackButton({
  menuName,
  sendUpdate,
  disabled,
}: {
  menuName: string;
  sendUpdate?: () => void;
  disabled: boolean;
}): JSX.Element {
  const Server = GetServer();
  if (menuName === "MainMenu") {
    return <></>;
  }
  function goBack() {
    if (disabled) {
      return;
    }
    Server.SendCommand("SetNavigatorButton", "GoBack", () => {});
    sendUpdate?.();
  }
  return (
    <img
      onClick={goBack}
      src={goBackImg}
      style={{
        cursor: disabled ? "default" : "pointer",
        right: 0,
        maxHeight: "2.3vmax",
        maxWidth: "2.3vmax",
      }}
      alt="go back"
    ></img>
  );
}
