import React from "react";
import { UiElement } from "../../Models/Menus";
import KeyPad from "./Pincode";
import { UiElementSlider } from "./UielementSlider";
import Button from "./interactive/Button";
import RectangleButton from "./interactive/RectangleButton";
import Switch from "./interactive/Switch";
import ValueAdjuster from "./interactive/ValueAdjuster";
import BigInfofield from "./noninteractive/BigInfoField";
import EmptyField from "./noninteractive/EmptyField";
import { ErrorLog } from "./noninteractive/ErrorLogs";
import Gauge from "./noninteractive/Gauge";
import Image from "./noninteractive/Image";
import TextField from "./noninteractive/TextField";
import Diode from "./noninteractive/diode";
import InfoField from "./noninteractive/infofield";
import StateInfoField from "./noninteractive/stateInfoField";
import TelemtryUiElement from "./telemtryUiElement";
import DataInfoField from "./noninteractive/DataInfoField";
import BigButton from "./interactive/BigButton";

type UiElementType = ({
  element,
  ScreenUpdate,
  disabled,
}: {
  element: UiElement;
  ScreenUpdate?: () => void;
  disabled: boolean;
}) => JSX.Element;
const UiElementChooserMap: Record<string, UiElementType> = {
  button: Button,
  diode: Diode,
  switch: Switch,
  infofield: InfoField,
  biginfofield: BigInfofield,
  bigbutton: BigButton,
  textfield: TextField,
  emptyfield: EmptyField,
  valueadjuster: ValueAdjuster,
  image: Image,
  gauge: Gauge,
  keypad: KeyPad,
  errorlog: ErrorLog,
  UiElement: TelemtryUiElement,
  uielementslider: UiElementSlider,
  stateinfofield: StateInfoField,
  rectanglebutton: RectangleButton,
  datainfofield: DataInfoField,
};
export default function UiElementChooser({
  element,
  ScreenUpdate,
  disabled,
}: {
  element: UiElement;
  ScreenUpdate?: (ignoreUpdateCheck?: boolean) => void;
  disabled: boolean;
}) {
  return (
    <UiElementView
      element={element}
      ScreenUpdate={ScreenUpdate}
      disabled={disabled}
    ></UiElementView>
  );
}
function UiElementView({
  element,
  ScreenUpdate,
  disabled,
}: {
  element: UiElement;
  ScreenUpdate?: () => void;
  disabled: boolean;
}) {
  let Element = UiElementChooserMap[element.type];
  if (
    element.type === "uielementslider" ||
    element.type === "button" ||
    element.type === "rectanglebutton"
  ) {
    return (
      <Element
        element={element}
        ScreenUpdate={ScreenUpdate}
        disabled={disabled}
      ></Element>
    );
  }
  return <Element element={element} disabled={disabled}></Element>;
}
