//this is the next task.

import React from "react";

import { GetPopUpBarListener } from "../../Models/HistoricViewlistener";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import ErrorLogView from "./ErrorLogView";
import TelemtryDataView from "./TelemetryView";
import InfoBox from "./infobox";
import DiodeBox from "./diodebox";

function classNames(...classes: (string | undefined | null | false)[]): string {
  return classes.filter(Boolean).join(" ");
}

type leftPanelType = "MachineInformation" | "InputsOutputs";

type dashBoardMenuView = () => JSX.Element | null;

const DashBoardMenuViews: Record<leftPanelType, dashBoardMenuView> = {
  MachineInformation: InfoBox,
  InputsOutputs: DiodeBox,
};
export default function LeftPanel() {
  const [dashBoardMenu, setDashBoardMenu] =
    React.useState<leftPanelType>("MachineInformation");

  let DashBoardMenuView = DashBoardMenuViews[dashBoardMenu];
  const server = GetServer();
  const listener = GetPopUpBarListener();

  return (
    <div className="">
      <div>
        <div className="">
          <nav
            aria-label="Tabs"
            className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
          >
            <button
              aria-current={
                dashBoardMenu === "MachineInformation" ? "page" : undefined
              }
              className={classNames(
                dashBoardMenu === "MachineInformation"
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                "rounded-l-lg",
                "group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10",
              )}
              onClick={() => {
                if (!server.machineIsRebooting()) {
                  listener.callListeners("UpTime");
                  setDashBoardMenu("MachineInformation");
                }
              }}
            >
              <span>Machine Information</span>
              <span
                aria-hidden="true"
                className={classNames(
                  dashBoardMenu === "MachineInformation"
                    ? "bg-blue-500"
                    : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5",
                )}
              />
            </button>
            <button
              aria-current={
                dashBoardMenu === "InputsOutputs" ? "page" : undefined
              }
              className={classNames(
                dashBoardMenu === "InputsOutputs"
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                "rounded-r-lg",
                "group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10",
              )}
              onClick={() => {
                if (!server.machineIsRebooting()) {
                  setDashBoardMenu("InputsOutputs");
                }
              }}
            >
              <span>Inputs and Outputs</span>
              <span
                aria-hidden="true"
                className={classNames(
                  dashBoardMenu === "InputsOutputs"
                    ? "bg-blue-500"
                    : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5",
                )}
              />
            </button>
          </nav>
        </div>
      </div>

      <div className="h-[650px] max-h-[650px] overflow-y-auto overflow-hidden" >
        <DashBoardMenuView />
      </div>
    </div>
  );
}
