import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const apiClient = axios.create({
  baseURL: API_URL + "/api/",
});

apiClient.interceptors.request.use((config) => {
  if (!config.skipAuth) {
    const token = JSON.parse(localStorage.getItem("token")).token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  }
  return config;
});

export default apiClient;
