import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useTelemetryDataQuery } from "hooks/machine";
// @ts-ignore
import {
  getSocket,
  joinRoom,
} from "shared/services/socket.service";

var format = require("date-format");
type Device = {
  [key: string]: any;
};
export default function TelemetryLineChart({
  machineData,
  showUnit,
  telemetryType,
}: {
  machineData: any;
  showUnit: boolean;
  telemetryType: string;
}) {
  //   const Server = GetServer();
  const [data, setData] = useState<Device[]>([]);
  const [loading, setLoading] = useState(true);

  const tickFormatter = (tick: any) => format("hh:mm:ss", new Date(tick));
  const labelFormatter = (date: any) =>
    format("dd/MM/yyyy hh:mm:ss", new Date(date));
  const {
    data: telemetryData,
    isLoading: machineLoading,
    error: machineError,
  } = useTelemetryDataQuery(machineData?.serialNumber);

  useEffect(() => {
    if (telemetryData) {
      const data = telemetryData?.data?.device;

      setData(data ?? []);

      setLoading(false);
    }
  }, [telemetryData]);
  useEffect(() => {
    let numberOfPumps = 0;
    let socket = getSocket();
    // console.log("sockeet", socket);
    if (socket) joinRoom(machineData?.serialNumber);

    socket.on("connect_error", (err: any) => {
      console.log("Connection Error socket", err);
    });

    socket.on("disconnect", () => {
      console.log("Disconnected socket");
      setTimeout(() => {
        socket.connect();
      }, 2000);
    });

    socket.on("connect", function () {
      console.log("Connected socket");
    });

    socket.on("reconnect", () => {
      console.log("Trying to reconnect socket.io");
    });

    socket.on("telemetry-" + machineData?.serialNumber, (data: any) => {
      const messageData = JSON.parse(data);
      let device = messageData.IotData;
      // console.log("device", device);
      var flowSwitch = 0;
      if (device.FlowSw > 1000) {
        flowSwitch = 10;
      }
      if (device.Temperature_Room > 1000) {
        device.Temperature_Room = 0;
      }
      if (device.Temperature_igbt > 1000) {
        device.Temperature_igbt = 0;
      }

      device = {
        ...device,
        EventEnqueuedUtcTime: messageData.MessageDate,
        Inverter_loadPowP: device.Inverter_loadPowP / 1000,
        FlowSw: flowSwitch,
      };

      if (data.length >= 270)
        setData((prevState) => [...prevState.slice(1), device]);
      //removing old telemetry data once a new one is added
      else setData((prevState) => [...prevState, device]); //removing old telemetry data once a new one is added
    });
  }, [machineData]);

  return (
    <ResponsiveContainer width={"100%"} height={350}>
      <LineChart
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        syncId={"telemetry-navigator"}
      >
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis
          dataKey="EventEnqueuedUtcTime"
          tickFormatter={tickFormatter}
          angle={30}
          //     dx={20}
          tickMargin={20}
          height={50}
          type="category"
        />
        <Tooltip
          // position={{ x: -20 }}
          allowEscapeViewBox={{ x: false, y: true }}
          labelFormatter={(t) => labelFormatter(t)}
          isAnimationActive={false}
        />
        <YAxis
          yAxisId="left"
          unit={getCardUnit(telemetryType)}
          domain={getYAxisDomain(telemetryType)}
          allowDecimals={true}
        />

        <Line
          stroke="#3498db"
          unit={getCardUnit(telemetryType)}
          strokeWidth={2}
          legendType="plainline"
          isAnimationActive={false}
          type="monotone"
          dataKey={telemetryType}
          name={getCardTitle(telemetryType)}
          dot={false}
          yAxisId="left"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

const getCardUnit = (telemetryType: string) => {
  const storedUnitSystem = localStorage.getItem("unitSystem") || "metric";

  const telemetryTypeRecord: Record<string, string> = {
    PressureIN: storedUnitSystem === "imperial" ? " psi" : " Bar",
    PressureOUT: storedUnitSystem === "imperial" ? " psi" : " Bar",
    Temperature_Water: storedUnitSystem === "imperial" ? " °F" : " °C",
    Temperature_igbt: storedUnitSystem === "imperial" ? " °F" : " °C",
    InverterFreqReadout: " Hz",
    Inverter_loadPowP: " Kw",
  };

  return telemetryTypeRecord[telemetryType];
};

const getCardTitle = (telemetryType: string) => {
  const telemetryTypeNames: Record<string, string> = {
    PressureIN: "Inlet Pressure",
    PressureOUT: "Outlet Pressure",
    Temperature_Water: "Water temperature",
    Temperature_igbt: "Power Module",
    InverterFreqReadout: "Inverter Frequency",
    Inverter_loadPowP: "Power",
  };
  return telemetryTypeNames[telemetryType];
};

const getYAxisDomain = (telemetryType: string) => {
  const storedUnitSystem = localStorage.getItem("unitSystem") || "metric";

  switch (telemetryType) {
    case "PressureIN":
    case "PressureOUT":
      return storedUnitSystem === "imperial" ? [0, 600] : [0, 40]; // Bar → PSI

    case "Temperature_Water":
    case "Temperature_igbt":
      return storedUnitSystem === "imperial" ? [30, 250] : [0, 120]; // °C → °F

    case "InverterFreqReadout":
      return [0, 100]; // Fixed range for frequency

    case "Inverter_loadPowP":
      return [0, 100]; // Fixed range for power (kW)

    default:
      return [0, 100]; // Default fallback
  }
};
