import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { searchFactories, searchOwnFactories } from "api/factory.service";
import { useSelector } from "react-redux";

export const useSearchOwnFactoriesQuery = (data) => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin = myAccount && (myAccount?.Role?.name === "Admin" || myAccount?.Role?.name === "Super Admin");

  return useQuery({
    queryKey: ["factories", data?.searchText, data?.page, data?.pageSize],
    queryFn: () => (isAdmin ? searchFactories(data) : searchOwnFactories(data)),
    staleTime: 1000,
    placeholderData: keepPreviousData,
  });
};
