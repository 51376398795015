import { Settings } from "./Settings";


export const AttributesTable : Record<string,Settings> = {
    AppSelectedRunPressure: {
      val: "3",
      uielement: "buttonChooserX",
      unit_name: "Bar",
      display_name: "Selected run pressure",
      options: [
        "9 bar",
        "1",
        "2 bar",
        "2",
        "16 bar",
        "3"
      ]
    },
    AppStandardPressure: {
      val: "2",
      uielement: "slider",
      unit_name: "Bar",
      display_name: "Standard pressure",
      low: "1",
      high: "25",
      interval: "1"
    },
    AppHighPressure: {
      val: "16",
      uielement: "slider",
      unit_name: "Bar",
      display_name: "High pressure",
      low: "1",
      high: "25",
      interval: "1"
    },
    AppLowPressure: {
      val: "9",
      uielement: "slider",
      unit_name: "Bar",
      display_name: "Low pressure",
      low: "1",
      high: "25",
      interval: "1"
    },
    AppUserPressure: {
      val: "15",
      uielement: "slider",
      unit_name: "Bar",
      display_name: "User pressure",
      low: "1",
      high: "25",
      interval: "1"
    },
    AppPostRunTime: {
      val: "18",
      uielement: "slider",
      unit_name: "Sec",
      display_name: "Post Run Time",
      low: "5",
      high: "60",
      interval: "1"
    },
    AppDryRunLevel: {
      val: "6",
      uielement: "slider",
      unit_name: "Bar",
      display_name: "Dry Run Level",
      low: "0.5",
      high: "4.0",
      interval: "0.1",
      scale: "0.1"
    },
    AppAccellerationRamp: {
      val: "60",
      uielement: "slider",
      unit_name: "Sec",
      display_name: "Accelleration Ramp",
      low: "3",
      high: "10",
      interval: "1",
      scale: "0.1"
    },
    AppStartUpMethod: {
      val: "1",
      uielement: "buttonChooser",
      unit_name: "Sec",
      display_name: "Start Up Method",
      options: [
        "na",
        "0",
        "Flow",
        "1",
        "Pressure",
        "2"
      ]
    },
    AppStartUpMethodPressVal: {
      val: "10",
      uielement: "slider",
      unit_name: "Bar",
      display_name: "Start up method pressure value",
      low: "3",
      high: "15",
      interval: "1"
    },
    AppQuickstartLevel: {
      val: "0",
      uielement: "toggle",
      unit_name: "bool",
      display_name: "Quick Start Level"
    },
    AppQuickstartLevelVal: {
      val: "5",
      uielement: "slider",
      unit_name: "Bar/Sec",
      display_name: "Quick Start Level Value",
      low: "5",
      high: "15",
      interval: "1"
    },
    AppAutoOffDelay: {
      val: "0",
      uielement: "toggle",
      unit_name: "bool",
      display_name: "Auto Off Delay"
    },
    AppAutoOffDelayVal: {
      val: "30",
      uielement: "slider",
      unit_name: "Min",
      display_name: "Auto Off Time Value",
      low: "5",
      high: "60",
      interval: "1"
    },
    AppSelectedLanguage: {
      val: "1",
      uielement: "na",
      unit_name: "int",
      display_name: "Selected Language"
    },
    AppSetAllowNoInlet: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "Set No Of Inlet"
    },
    AppDelayedStart: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "Delayed Star"
    },
    AppDelayedStartTime: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "Delayed Start Time"
    },
    AppLowLevelAlarmTime: {
      val: "2000",
      uielement: "na",
      unit_name: "int",
      display_name: "Low Level Alarm Time"
    },
    AppScreenSaverTime: {
      val: "1200000",
      uielement: "buttonChooser",
      unit_name: "Min",
      display_name: "Screen Saver Time",
      options: [
        "Off",
        "0",
        "10",
        "600000",
        "20",
        "1200000",
        "30",
        "1800000"
      ]
    },
    AppScreenSaverType: {
      val: "0",
      uielement: "buttonChooser",
      unit_name: "screen",
      display_name: "Screen Saver Type",
      options: [
        "Logo",
        "0",
        "Clock",
        "1",
        "na",
        "2",
        "H7",
        "3"
      ]
    },
    AppUnitType: {
      val: "0",
      uielement: "buttonChooser",
      unit_name: "unit",
      display_name: "Select Unit Type",
      options: [
        "SI",
        "0",
        "Imperial",
        "1"
      ]
    },
    AppBoosterType: {
      val: "1",
      uielement: "na",
      unit_name: "int",
      display_name: "Booster Typr"
    },
    AppBrand: {
      val: "1",
      uielement: "na",
      unit_name: "int",
      display_name: "Brand"
    },
    AppAutoIpAddress: {
      val: "1",
      uielement: "na",
      unit_name: "int",
      display_name: "Auto IP DHCP"
    },


    whichDnsInUse: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "DNS in use"
    },
    "AppMacAddress(NOT USED)": {
      val: "SntpTimeSetRtcOn",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    SntpTimeSetRtcOn: {
      val: "1",
      uielement: "na",
      unit_name: "int",
      display_name: "SNTP ON"
    },
 
    UseDaylightSavingTimeOn: {
      val: "1",
      uielement: "na",
      unit_name: "int",
      display_name: "Use Daylight Save"
    },

    booster5kW: {
      val: "1",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    FoamaticType: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    WashProgramPin: {
      val: "0000",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    MaxPauseTime: {
      val: "10",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    DACScale: {
      val: "25",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    InvModbusSlaveId: {
      val: "1",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    InvModbusSlavesOnBus: {
      val: "1",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    OtherModbusSlaveId: {
      val: "10",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    OtherModbusSlavesOnBus: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    InternalDACScale: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    FlowDetectDelay: {
      val: "10",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    Flowdetectactive: {
      val: "1",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    AreaValveFeedbackTime: {
      val: "15",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    LockHourStart: {
      val: "12",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    LockMinStart: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    LockHourEnd: {
      val: "12",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    LockMinEnd: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    LockDays: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    SessionHourStart: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    SessionMinStart: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    SessionHourEnd: {
      val: "23",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    SessionMinEnd: {
      val: "59",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    FlowSensorMinFlow: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    FlowSensorMaxFlow: {
      val: "300",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    FlowSensorMaxSignal: {
      val: "10",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    FlowSensorMinSignal: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    AzureAuthenticateCode: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    AppReadAdcIndex: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    ProgramFinishedTime: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },

    InvPresSensor1: {
      val: "1",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    InvPresSensor2: {
      val: "1",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    InvPresSensor3: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    DryRunLevelDelta: {
      val: "5",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    DryRunSensorLoadLow: {
      val: "1",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    DryRunLowSensorSignal: {
      val: "-3",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    CompressorStartLevel: {
      val: "70",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    P3LowSensorSignal: {
      val: "0",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    },
    ParamUpdated: {
      val: "1",
      uielement: "na",
      unit_name: "int",
      display_name: "NA"
    }
  };